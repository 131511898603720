// React Router
import { Routes, Route, BrowserRouter } from "react-router-dom";

// React Pages
import UserAuthPage from "./pages/userAuthPage";
import HomePage from "./pages/homePage";
import BrawlVisualsPage from "./pages/brawlVisualsPage";
import TestingPage from "./pages/testingPage";
import Error404Page from "./pages/error_404_Page";

// Settings Context
import { SettingsProvider } from "./settingsContext";

// CSS
import "./App.css";

function App() {
  return (
    <div>
      <SettingsProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<UserAuthPage />} />
            <Route path="/auth" element={<UserAuthPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/brawl" element={<BrawlVisualsPage />} />
            <Route path="/testingpage" element={<TestingPage />} />
            <Route path="*" element={<Error404Page />} />
          </Routes>
        </BrowserRouter>
      </SettingsProvider>
    </div>
  );
}

export default App;
