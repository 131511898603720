// React
import { useEffect, useState } from "react";

// React Components
import BankContents from "./Tabs/BankContents";

// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import {
  itemCategories,
  itemRarities,
  openBrawlsTabImg,
} from "../../Constants";

// CSS
import "./bank-popout.css";

export default function BankPopout() {
  const [itemStats, setItemStats] = useState({ damage: 0, health: 0 });

  const { userDocRef, showBankPopout, setShowBankPopout } = useSettings();

  const equippedItems = itemCategories.map((category) => ({
    slotName: category,
    data: userDocRef.equippedItems[category],
  }));

  useEffect(() => {
    CalculateItemStats();
  });

  const CalculateItemStats = () => {
    itemStats.damage = 0;
    itemStats.health = 0;
    equippedItems.forEach(({ slotName, data }) => {
      const statValue =
        itemRarities[data.itemRarity as keyof typeof itemRarities].statValue;
      if (slotName === "weapon") {
        itemStats.damage += statValue;
      } else {
        itemStats.health += statValue;
      }

      setItemStats(itemStats);
    });
  };

  return (
    <div className={`bank-popout-div ${showBankPopout ? "open" : "close"}`}>
      <div className="bank-popout-tab-container">
        <button
          onClick={() => {
            setShowBankPopout(false);
          }}
          className="bank-popout-close-btn"
        >
          <b>X</b>
        </button>
        <button className="bank-popout-tab-btn">
          <div className="bank-popout-tab-overlay" />
          <img src={openBrawlsTabImg} className="bank-popout-tab-img"></img>
          <p className="bank-popout-tab-txt">The Bank</p>
        </button>
      </div>
      <div className="bank-popout-stat-container">
        <div className="bank-popout-stat-value-container">
          <h4 className="bank-popout-stat-name-txt">Extra Damage:</h4>
          <h4 className="bank-popout-stat-value-txt">{itemStats.damage}</h4>
        </div>
        <div className="bank-popout-stat-value-container">
          <h4 className="bank-popout-stat-name-txt">Extra Health:</h4>
          <h4 className="bank-popout-stat-value-txt">{itemStats.health}</h4>
        </div>
      </div>
      <BankContents equippedItems={equippedItems} />
    </div>
  );
}
