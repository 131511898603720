// React
import { useEffect, useState } from "react";

// Constants
import { hospitalFee, userTableName } from "../../Constants";

// Settings Context
import { useSettings } from "../../settingsContext";

// Firebase
import { UpdateDocumentOnFirestore } from "../../firebaseconfig";
import { increment } from "firebase/firestore";

// CSS
import "./hospital-popout.css";

export default function QuestsPopout() {
  const { userDocRef, showHospitalPopout, setShowHospitalPopout } =
    useSettings();

  const [btnTxt, setBtnTxt] = useState(
    userDocRef.health === userDocRef.maxHealth
      ? "Full Health"
      : "Restore Health"
  );

  useEffect(() => {
    setBtnTxt(
      userDocRef.health === userDocRef.maxHealth
        ? "Full Health"
        : "Restore Health"
    );
  });

  const RestoreHealth = async () => {
    if (userDocRef.$Brawl >= hospitalFee) {
      await UpdateDocumentOnFirestore(userTableName, userDocRef.userID, {
        $Brawl: increment(-hospitalFee),
        health: userDocRef.maxHealth,
      });

      setBtnTxt("Health Restored");
      setTimeout(() => setBtnTxt("Full Health"), 3000);
    } else {
      setBtnTxt("Insufficient $Brawl for healing");
      setTimeout(() => setBtnTxt("Restore Health"), 3000);
    }
  };

  return (
    <div
      className={`hospital-popout-div ${showHospitalPopout ? "open" : "close"}`}
    >
      <div className="hospital-popout-tab-container">
        <button
          onClick={() => {
            setShowHospitalPopout(false);
          }}
          className="quest-popout-close-btn"
        >
          <b>X</b>
        </button>

        <button
          className="hospital-popout-tab-btn"
          style={{
            borderTopRightRadius: "1rem",
            borderBottomRightRadius: "1rem",
          }}
        >
          <div className="hospital-popout-tab-overlay" />
          <img
            src="{createBrawlTabImg}"
            className="hospital-popout-tab-img"
          ></img>
          <p className="hospital-popout-tab-txt">Hospital</p>
        </button>
      </div>
      <div className="hospital-popout-container">
        <p className="hospital-description-txt">
          Healing yourself costs 15 $Brawl and will restore you to full health.
          Do you wish to heal yourself?
        </p>
      </div>
      <div className="hospital-popout-heal-btn-container">
        <button
          onClick={async () => {
            RestoreHealth();
          }}
          disabled={userDocRef.health === userDocRef.maxHealth ? true : false}
          className="hospital-popout-heal-btn"
          style={
            userDocRef.health === userDocRef.maxHealth
              ? { backgroundColor: "#888888" }
              : {
                  backgroundImage:
                    "linear-gradient(to right, #2cb454, #029411)",
                }
          }
        >
          {btnTxt}
        </button>
      </div>
    </div>
  );
}
