// Constants
import { authHeroImg } from "../../Constants";

// CSS
import "./auth-hero-image.css";

export default function AuthHeroImage() {
  return (
    <div className="auth-hero-img-div">
      <img src={authHeroImg} className="auth-hero-img"></img>
    </div>
  );
}
