// React
import { useEffect, useState } from "react";

// React Components
import TransitionScreen from "../../../TransitionScreen/TransitionScreen";

// Constants
import {
  basicMoves,
  blockColor,
  buffs,
  damageColor,
  debuffs,
  healColor,
  specialMoves,
} from "../../../../Constants";

// Global Functions
import { getStatValue, toCamelCase } from "../../../../globalFunctions";

// Settings Context
import { useSettings } from "../../../../settingsContext";

// CSS
import "./brawling-screen.css";

interface BrawlingScreenProps {
  damageTaken: any;
  roundLogs: any;
  playerStatus: any;
  player1Move: string;
  player2Move: string;
}

export default function BrawlingScreen({
  damageTaken,
  roundLogs,
  playerStatus,
  player1Move,
  player2Move,
}: BrawlingScreenProps) {
  const { currentBrawlRef, userDocRef, transitionActive } = useSettings();

  const [showEffectFlash, setShowEffectFlash] = useState({
    player1: "none",
    player2: "none",
  });
  const [damageText, setDamageText] = useState<any>({
    player1: [],
    player2: [],
  });

  useEffect(() => {
    // Get the relevant user visuals for the damage numbers to appear from
    const leftImgContainer = document.getElementById("left-img-container");
    const rightImgContainer = document.getElementById("right-img-container");

    // Calculate damage number values
    const textValues = CalculateDamageNumbers(player1Move, player2Move);

    if (
      textValues.player1Numbers.damageReceived === 0 &&
      textValues.player1Numbers.damageToBlock > 0
    ) {
      showEffectFlash.player1 = "block";
    } else if (textValues.player1Numbers.damageReceived > 0) {
      showEffectFlash.player1 = "damage";
    } else if (textValues.player1Numbers.heal > 0) {
      showEffectFlash.player1 = "heal";
    }

    if (
      textValues.player2Numbers.damageReceived === 0 &&
      textValues.player2Numbers.damageToBlock > 0
    ) {
      showEffectFlash.player2 = "block";
    } else if (textValues.player2Numbers.damageReceived > 0) {
      showEffectFlash.player2 = "damage";
    } else if (textValues.player2Numbers.heal > 0) {
      showEffectFlash.player2 = "heal";
    }

    setShowEffectFlash(showEffectFlash);

    setTimeout(() => {
      showEffectFlash.player1 = "none";
      showEffectFlash.player2 = "none";
      setShowEffectFlash(showEffectFlash);
    }, 100);

    if (textValues.player1Numbers.enemyDamage > 0) {
      damageText.player1.push({
        value: `-${textValues.player1Numbers.enemyDamage}`,
        color: damageColor,
      });
    } else if (textValues.player1Numbers.selfDamage > 0) {
      damageText.player1.push({
        value: `-${textValues.player1Numbers.selfDamage}`,
        color: damageColor,
      });
    } else if (textValues.player1Numbers.heal > 0) {
      damageText.player1.push({
        value: `+${textValues.player1Numbers.heal}`,
        color: healColor,
      });
    } else if (textValues.player1Numbers.damageToBlock > 0) {
      damageText.player2.push({
        value: `${textValues.player1Numbers.damageToBlock}`,
        color: blockColor,
      });
    }

    if (textValues.player2Numbers.enemyDamage > 0) {
      damageText.player2.push({
        value: `-${textValues.player2Numbers.enemyDamage}`,
        color: damageColor,
      });
    } else if (textValues.player2Numbers.selfDamage > 0) {
      damageText.player2.push({
        value: `-${textValues.player2Numbers.selfDamage}`,
        color: damageColor,
      });
    } else if (textValues.player2Numbers.heal > 0) {
      damageText.player2.push({
        value: `+${textValues.player2Numbers.heal}`,
        color: healColor,
      });
    } else if (textValues.player2Numbers.damageToBlock > 0) {
      damageText.player1.push({
        value: `${textValues.player2Numbers.damageToBlock}`,
        color: blockColor,
      });
    }

    if (currentBrawlRef.live) {
      damageText.player2.forEach((data: any) => {
        CreateDamageNumber(data.value, data.color, leftImgContainer);
      });
      damageText.player1.forEach((data: any) => {
        CreateDamageNumber(data.value, data.color, rightImgContainer);
      });
    } else {
      damageText.player1.forEach((data: any) => {
        CreateDamageNumber(data.value, data.color, leftImgContainer);
      });
      damageText.player2.forEach((data: any) => {
        CreateDamageNumber(data.value, data.color, rightImgContainer);
      });
    }

    setDamageText(damageText);
  }, [player1Move, player2Move]);

  const CalculateDamageNumbers = (player1Move: string, player2Move: string) => {
    const player1WeaponDamage = getStatValue(
      currentBrawlRef.player1WeaponRarity
    );
    const player2WeaponDamage = getStatValue(
      currentBrawlRef.live
        ? userDocRef.equippedItems.weapon.itemRarity
        : currentBrawlRef.player2WeaponRarity
    );

    const moves = [
      { ...basicMoves[player1Move] },
      { ...basicMoves[player2Move] },
    ];

    const moveData = [
      {
        damageReceived: 0,
        enemyDamage: 0,
        selfDamage: 0,
        damageToBlock: 0,
        heal: 0,
      },
      {
        damageReceived: 0,
        enemyDamage: 0,
        selfDamage: 0,
        damageToBlock: 0,
        heal: 0,
      },
    ];

    moves.map((move, index) => {
      const otherIndex = (index + 1) % moves.length; // Index of the other player
      const otherMove = moves[otherIndex]; // Gets the other item
      const extraDamage =
        index === 0 ? player1WeaponDamage : player2WeaponDamage;

      if (move.type === "atk") {
        moveData[index].enemyDamage += move.value + extraDamage;
        moveData[otherIndex].damageReceived += move.value + extraDamage;
      } else if (move.type === "blk") {
        moveData[index].damageToBlock += basicMoves.block.value;
        moveData[index].damageReceived -= basicMoves.block.value;
      } else if (move.type === "special") {
        let specialMove =
          index === 0
            ? currentBrawlRef.player1SpecialMove
            : currentBrawlRef.live
            ? userDocRef.specialMove
            : currentBrawlRef.player2SpecialMove;

        if (specialMove === "counter") {
          if (otherMove.type === "special") {
            specialMove =
              index === 0
                ? currentBrawlRef.live
                  ? userDocRef.specialMove
                  : currentBrawlRef.player2SpecialMove
                : currentBrawlRef.player1SpecialMove;
          } else {
            if (otherMove.name === "jab" || otherMove.name === "uppercut") {
              moveData[index].enemyDamage = move.value + extraDamage;
              moveData[otherIndex].damageReceived += move.value + extraDamage;
            } else if (otherMove.name === "block") {
              moveData[index].damageToBlock += basicMoves.block.value;
              moveData[index].damageReceived -= basicMoves.block.value;
            }
          }
        }

        const specialMoveStats =
          specialMoves[toCamelCase(specialMove) as keyof typeof specialMoves]
            .stats;

        moveData[index].enemyDamage +=
          specialMoveStats.enemyDamage + extraDamage;
        moveData[index].selfDamage += specialMoveStats.selfDamage;
        moveData[index].damageToBlock += specialMoveStats.damageToBlock;
        moveData[otherIndex].damageReceived +=
          specialMoveStats.enemyDamage + extraDamage;
        moveData[index].damageReceived += specialMoveStats.selfDamage;
        moveData[index].damageReceived -= specialMoveStats.damageToBlock;

        const player =
          index === 0 ? playerStatus.player1 : playerStatus.player2;

        const otherPlayer =
          index === 0 ? playerStatus.player2 : playerStatus.player1;

        // Heal player 2 for 4 health
        moveData[index].heal += specialMoveStats.heal;

        if (otherPlayer.debuffs.name !== "null") {
          move.value +=
            debuffs[
              otherPlayer.debuffs.name as keyof typeof debuffs
            ].damagePerTurn;

          otherPlayer.debuffs.turnsRemaining -= 1;

          if (otherPlayer.debuffs.turnsRemaining <= 0) {
            otherPlayer.debuffs.name = "null";
            otherPlayer.debuffs.turnsRemaining = 0;
          }
        }

        if (player.buffs.name !== "null") {
          move.value *=
            buffs[player.buffs.name as keyof typeof buffs].damageMultiplier;

          player.buffs.turnsRemaining -= 1;

          if (player.buffs.turnsRemaining <= 0) {
            player.buffs.name = "null";
            player.buffs.turnsRemaining = 0;
          }
        }

        const specialMoveDebuff =
          specialMoves[toCamelCase(specialMove) as keyof typeof specialMoves]
            .debuff;

        const specialMoveBuff =
          specialMoves[toCamelCase(specialMove) as keyof typeof specialMoves]
            .buff;

        if (specialMoveDebuff && "name" in specialMoveDebuff) {
          otherPlayer.debuffs = {
            name: specialMoveDebuff.name,
            turnsRemaining: specialMoveDebuff.turnDuration,
          };
        }

        if (specialMoveBuff && "name" in specialMoveBuff) {
          player.buffs = {
            name: specialMoveBuff.name,
            turnsRemaining: specialMoveBuff.turnDuration,
          };
        }
      }
    });

    moveData[0].enemyDamage -= moveData[1].damageToBlock;
    moveData[1].enemyDamage -= moveData[0].damageToBlock;

    if (moveData[0].enemyDamage < 0) {
      moveData[0].enemyDamage = 0;
    }

    if (moveData[1].enemyDamage < 0) {
      moveData[1].enemyDamage = 0;
    }

    return {
      player1Numbers: moveData[1],
      player2Numbers: moveData[0],
    };
  };

  function CreateDamageNumber(value: any, color: any, parent: any) {
    const number = document.createElement("div");
    number.classList.add("brawling-screen-damage-numbers");
    number.textContent = value;
    number.style.color = color;

    // Generate random angle and velocity
    const angle = Math.random() * 2 * Math.PI;
    const velocity = 100 + Math.random() * 100; // Pixels per second
    const dx = Math.cos(angle) * velocity;
    const dy = Math.sin(angle) * velocity;

    // Set CSS custom properties for the animation
    number.style.setProperty("--dx", `${dx}px`);
    number.style.setProperty("--dy", `${dy}px`);

    number.style.animation = "float 2s forwards, fade 3s forwards";

    // Append to parent container
    parent.appendChild(number);

    // Remove element after animation ends
    number.addEventListener("animationend", () => {
      number.remove();
      damageText.player1 = [];
      damageText.player2 = [];
      setDamageText(damageText);
    });
  }

  return (
    <div className="brawling-screen-body">
      <div className="brawling-screen-vs-container">
        <div className="brawling-screen-profile-container">
          <div
            id="left-img-container"
            className="brawling-screen-vs-img-container"
          >
            <div
              className={`brawling-screen-flash-effect-overlay ${
                currentBrawlRef.live
                  ? showEffectFlash.player2
                  : showEffectFlash.player1
              }`}
            />
            <img
              src={userDocRef.profilePic}
              className="brawling-screen-vs-img"
            ></img>
          </div>
          <div className="brawling-screen-health-bar-body">
            <div
              className="brawling-screen-health-bar-green"
              style={
                currentBrawlRef.live
                  ? {
                      width: `${
                        (playerStatus.player2.health /
                          playerStatus.player2.maxHealth) *
                        100
                      }%`,
                    }
                  : {
                      width: `${
                        (playerStatus.player1.health /
                          playerStatus.player1.maxHealth) *
                        100
                      }%`,
                    }
              }
            ></div>
            <div className="brawling-screen-health-bar-red"></div>
            <p className="brawling-screen-health-bar-txt">
              {currentBrawlRef.live ? damageTaken.player2 : damageTaken.player1}{" "}
              Damage Taken
            </p>
          </div>
          <div className="brawling-screen-name-txt">{userDocRef.username}</div>
        </div>
        <div className="brawling-screen-txt-container">
          <div className="brawling-screen-wager-txt">VERSUS</div>
        </div>
        <div className="brawling-screen-profile-container">
          <div
            id="right-img-container"
            className="brawling-screen-vs-img-container"
          >
            <div
              className={`brawling-screen-flash-effect-overlay ${
                currentBrawlRef.live
                  ? showEffectFlash.player2
                  : showEffectFlash.player1
              }`}
            />
            <img
              src={
                currentBrawlRef.live
                  ? currentBrawlRef.player1ProfilePic
                  : currentBrawlRef.player2ProfilePic
              }
              className="brawling-screen-vs-img"
            ></img>
          </div>

          <div className="brawling-screen-health-bar-body">
            <div
              className="brawling-screen-health-bar-green"
              style={
                currentBrawlRef.live
                  ? {
                      width: `${
                        (playerStatus.player1.health /
                          playerStatus.player1.maxHealth) *
                        100
                      }%`,
                    }
                  : {
                      width: `${
                        (playerStatus.player2.health /
                          playerStatus.player2.maxHealth) *
                        100
                      }%`,
                    }
              }
            ></div>
            <div className="brawling-screen-health-bar-red"></div>
            <p className="brawling-screen-health-bar-txt">
              {currentBrawlRef.live ? damageTaken.player1 : damageTaken.player2}{" "}
              Damage Taken
            </p>
          </div>
          <div className="brawling-screen-name-txt">
            {currentBrawlRef.live
              ? currentBrawlRef.player1Username
              : currentBrawlRef.player2Username}
          </div>
        </div>
      </div>
      <div className="brawling-screen-log-wrapper">
        <div className="brawling-screen-log-body">
          {roundLogs.map((roundLog: any, index: number) => (
            <div key={index} className="brawling-screen-log-text">
              {roundLog.log}
            </div>
          ))}
        </div>
        <div className="brawling-screen-log-mask"></div>
      </div>
      <TransitionScreen
        transitionActive={transitionActive}
        startOnCreation={false}
      />
    </div>
  );
}
