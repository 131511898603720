// React Components
import QuestItem from "../Items/QuestItem";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Constants
import { staticQuestData, staticWeeklyQuestData } from "../../../Constants";

// CSS
import "./quests-list.css";

interface QuestsProps {
  type: string;
}

export default function QuestsList({ type }: QuestsProps) {
  const { userDocRef } = useSettings();

  const GenerateQuests = () => {
    const { questLines, weeklyQuests } = userDocRef;

    // If the quests belong to a questline
    if (type === "questlines") {
      const quests = [
        { quests: questLines.playBrawls, type: "playBrawls" },
        { quests: questLines.startBrawls, type: "startBrawls" },
        { quests: questLines.winBrawls, type: "winBrawls" },
      ];

      return quests.flatMap(({ quests, type }) => {
        let selectedQuest = quests.find((questData: any) => !questData.claimed);

        const staticQuest =
          staticQuestData[type as keyof typeof staticQuestData][
            questLines[type].indexOf(selectedQuest)
          ];

        return selectedQuest ? (
          <QuestItem
            key={selectedQuest.id}
            questData={{
              ...selectedQuest,
              title: staticQuest.title,
              requirement: staticQuest.requirement,
              reward: staticQuest.reward,
              questIndex: questLines[type].indexOf(selectedQuest),
              questType: type,
            }}
          />
        ) : null;
      });

      // If the quests belong to the weekly quests
    } else {
      const weeklyQuestKeys = Object.keys(weeklyQuests);

      return weeklyQuestKeys.map((questKey, index) => (
        <QuestItem
          key={index}
          questData={{
            ...weeklyQuests[questKey],
            title:
              staticWeeklyQuestData[
                questKey as keyof typeof staticWeeklyQuestData
              ].title,
            requirement:
              staticWeeklyQuestData[
                questKey as keyof typeof staticWeeklyQuestData
              ].requirement,
            reward:
              staticWeeklyQuestData[
                questKey as keyof typeof staticWeeklyQuestData
              ].reward,
          }}
        />
      ));
    }
  };

  return (
    <div>
      <div className="quests-list-div">{GenerateQuests()}</div>
    </div>
  );
}
