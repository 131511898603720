// React
import React from "react";

interface ImageUploadProps {
  profilePic: any;
  setProfilePic: any;
  size: number;
}

function ImageUpload({ profilePic, setProfilePic, size }: ImageUploadProps) {
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        if (typeof result === "string") {
          setProfilePic(result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div
        className="card card-sizing"
        style={{
          width: `${size}rem`,
          height: `${size}rem`,
          display: "flex",
          flexDirection: "column",
          borderRadius: "100%",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={() => document.getElementById("file-upload")?.click()}
      >
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        />
        <img
          src={profilePic}
          className="custom-card-img"
          alt="banner builder item"
          draggable={false}
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "100%",
            objectFit: "cover",
            zIndex: 10,
          }}
        />
      </div>
    </div>
  );
}

export default ImageUpload;
