// Constants
import { armourColor, itemRarities, weaponColor } from "../../../Constants";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Global Functions
import { getStatValue, getRarityColor } from "../../../globalFunctions";

// CSS
import "./bank-item.css";

interface BankItemProps {
  slotName: string;
  itemIcon: any;
  itemRarity?: string;
  itemCollection?: string;
  setActiveTab?: any;
  setActiveCollection?: any;
}

export default function BankItem({
  slotName,
  itemIcon,
  itemRarity,
  itemCollection,
  setActiveTab,
  setActiveCollection,
}: BankItemProps) {
  const { userDocRef } = useSettings();

  const CalculateCollectionProgress = () => {
    const items = userDocRef.inventory[slotName];
    const rarities: string[] = [];
    let progressionCount = 0;

    for (const key in itemRarities) {
      if (itemRarities[key as keyof typeof itemRarities].name !== "default") {
        rarities.push(itemRarities[key as keyof typeof itemRarities].name);
      }
    }

    if (itemCollection && items && items[itemCollection.toLowerCase()]) {
      rarities.forEach((rarity) => {
        if (items[itemCollection.toLowerCase()][rarity]) {
          progressionCount += 1;
        }
      });
    }
    return progressionCount;
  };

  return (
    <button
      onClick={() => {
        if (typeof setActiveTab === "function") {
          setActiveTab(slotName);
        }

        if (typeof setActiveCollection === "function") {
          setActiveCollection(itemCollection);
        }

        console.log(itemCollection);
      }}
      className="bank-item-btn"
    >
      <div
        className="bank-item-img-container"
        style={{
          backgroundColor: getRarityColor(itemRarity ? itemRarity : ""),
        }}
      >
        <img src={itemIcon} className="bank-item-img"></img>
        {itemRarity ? (
          <div
            className="bank-item-stat-container"
            style={
              slotName === "weapon"
                ? { backgroundColor: weaponColor }
                : { backgroundColor: armourColor }
            }
          >
            <p className="bank-item-img-stat-txt">
              +{getStatValue(itemRarity)}{" "}
              {slotName === "weapon" ? "Damage" : "Health"}
            </p>
          </div>
        ) : null}
      </div>
      <div className="bank-item-details-container">
        <h4 className="bank-item-slot-txt">
          {slotName.charAt(0).toUpperCase() + slotName.slice(1)}
        </h4>
        {itemCollection ? (
          itemRarity ? (
            <p className="bank-item-collection-txt">
              {itemCollection.charAt(0).toUpperCase() + itemCollection.slice(1)}
            </p>
          ) : (
            <p className="bank-item-collection-txt">
              {userDocRef.inventory[slotName] &&
              userDocRef.inventory[slotName][itemCollection.toLowerCase()]
                ? itemCollection
                : "???"}
            </p>
          )
        ) : null}
        {itemRarity ? (
          <p className="bank-item-rarity-txt">
            {itemRarity.charAt(0).toUpperCase() + itemRarity.slice(1)}
          </p>
        ) : itemCollection ? (
          <p className="bank-item-rarity-txt">
            {CalculateCollectionProgress()}/
            {Object.keys(itemRarities).length - 1} collected
          </p>
        ) : null}
      </div>
    </button>
  );
}
