// React
import { useState } from "react";

// React Components
import AccountManager from "./Tabs/AccountManager";
import AccountOverview from "./Tabs/AccountOverview";

// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import { normalBtnColorStyle } from "../../Constants";

// CSS
import "./account-mapover.css";

export default function AccountMapover() {
  const { setShowAccountMapover } = useSettings();

  const [activeTab, setActiveTab] = useState("overview");

  return (
    <div className="account-mapover-backdrop">
      <div className="account-mapover-div">
        <div className="account-mapover-nav-btn-container">
          <button
            onClick={() => {
              setActiveTab("overview");
            }}
            className="account-mapover-nav-btn"
            style={
              activeTab === "overview"
                ? normalBtnColorStyle
                : { backgroundColor: "#888888" }
            }
          >
            Stats
          </button>
          <button
            onClick={() => {
              setActiveTab("manage");
            }}
            className="account-mapover-nav-btn"
            style={
              activeTab === "manage"
                ? normalBtnColorStyle
                : { backgroundColor: "#888888" }
            }
          >
            Manage
          </button>
        </div>
        <div className="account-mapover-container">
          <button
            onClick={() => {
              setShowAccountMapover(false);
            }}
            className="account-mapover-close-btn"
          >
            <b>X</b>
          </button>
          {activeTab === "manage" ? <AccountManager /> : <AccountOverview />}
        </div>
      </div>
    </div>
  );
}
