// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import { unsetSpecialPic } from "../../Constants";

// CSS
import "./navbar.css";

export default function Navbar() {
  const { userDocRef, setShowAccountMapover } = useSettings();

  return (
    <div className="navbar-div">
      <div>
        <div className="navbar-row">
          <img src={userDocRef.profilePic} className="navbar-profile-img"></img>

          <div className="navbar-profile-container">
            <img src={unsetSpecialPic} className="navbar-special-img"></img>
            <h4>SPECIAL</h4>
          </div>
        </div>

        <div className="navbar-healthbar-container">
          <div
            className="navbar-green-health"
            style={{
              width: `${(userDocRef.health / userDocRef.maxHealth) * 100}%`,
            }}
          ></div>
          <div className="navbar-red-health"></div>
          <p className="navbar-health-txt">
            {userDocRef.health}/{userDocRef.maxHealth} HP
          </p>
        </div>
        <div className="navbar-row">
          <div className="navbar-item-container">
            {userDocRef.$Brawl} $Brawl
          </div>
          <div>
            <button
              onClick={() => {
                setShowAccountMapover(true);
              }}
              className="navbar-item-container"
            >
              Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
