// Settings Context
import { useSettings } from "../../../../../settingsContext";

// CSS
import "./move-select-edit-button.css";

interface MoveSelectEditBtnProps {
  move: string;
  round: number;
  setCurrentRound: any;
}

export default function MoveSelectEditBtn({
  move,
  round,
  setCurrentRound,
}: MoveSelectEditBtnProps) {
  const { userDocRef } = useSettings();

  return (
    <button
      onClick={() => {
        setCurrentRound(round);
      }}
      className="move-select-edit-btn"
    >
      <div className="move-select-edit-btn-round-txt">Round {round}</div>
      <div className="move-select-edit-btn-move-txt">
        {move === "special" ? userDocRef.specialMove : move}
      </div>
    </button>
  );
}
