// React
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Firebase
import { SignInUser } from "../../firebaseconfig";

// Settings Context
import { useSettings } from "../../settingsContext";

// CSS
import "./sign-in-ui.css";

interface SignInProps {
  setActiveComponent: any;
  email: string;
  setEmail: any;
  password: string;
  setPassword: any;
}

export default function SignInUI({
  setActiveComponent,
  email,
  setEmail,
  password,
  setPassword,
}: SignInProps) {
  const { setUserDocRef } = useSettings();
  const [warningMessage, setWarningMessage] = useState(
    "Failed to sign in. Check email or password are correct."
  );
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const navigate = useNavigate();

  const TriggerSignIn = async (email: string, password: string) => {
    try {
      const result = await SignInUser(email, password);
      if (result.success === true) {
        setUserDocRef(result.docRef);
        navigate("/home");
      } else {
        setWarningMessage(
          "Failed to sign in. Check email or password are correct."
        );
        setShowWarningMessage(true);
      }
    } catch (error) {
      console.log(error);
      setWarningMessage(
        "Failed to sign in. Check email or password are correct."
      );
      setShowWarningMessage(true);
    }
  };

  return (
    <div>
      <h2 className="sign-in-title">SIGN IN TO BRAWLCLUB.</h2>
      <div className="form-container">
        <div>
          <label className="form-label">Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="name@example.com"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          ></input>
        </div>
        <div>
          <label className="form-label input-margin">Password</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          ></input>
        </div>
      </div>
      <div>
        {showWarningMessage ? (
          <p className="sign-in-warning-txt">{warningMessage}</p>
        ) : null}
      </div>
      <div>
        <button
          onClick={() => {
            TriggerSignIn(email, password);
          }}
          type="button"
          className="sign-in-btn"
        >
          Sign In
        </button>
        <p className="switch-to-create-txt">
          Don't have a BrawlClub account?
          <button
            onClick={() => {
              setActiveComponent("createuser");
            }}
            type="button"
            className="switch-to-create-btn"
          >
            Create an account.
          </button>
        </p>
      </div>
    </div>
  );
}
