// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import { unsetProfilePic, userTableName } from "../../Constants";

// Firestore
import { increment } from "firebase/firestore";
import { UpdateDocumentOnFirestore } from "../../firebaseconfig";

// CSS
import "./marketplace-item.css";

interface ShopItemProps {
  moveData: any;
  equipped: boolean;
}

export default function MarketplaceItem({ moveData, equipped }: ShopItemProps) {
  const { userDocRef } = useSettings();

  const BuyAndEquipSpecial = async () => {
    await UpdateDocumentOnFirestore(userTableName, userDocRef.userID, {
      $Brawl: increment(-moveData.cost),
      specialMove: moveData.name,
    });
  };

  return (
    <div
      className="marketplace-item-div"
      style={equipped ? { width: "35%" } : {}}
    >
      <img src={unsetProfilePic} className="marketplace-item-img"></img>
      <div className="marketplace-item-details-container">
        <h3 className="marketplace-item-name-txt">{moveData.name}</h3>
        <p className="marketplace-item-description-txt">
          {moveData.description}
        </p>
      </div>
      {equipped ? null : (
        <button
          onClick={async () => {
            await BuyAndEquipSpecial();
          }}
          className="marketplace-item-btn"
          style={
            userDocRef.$Brawl >= moveData.cost
              ? {
                  backgroundImage:
                    "linear-gradient(to right, #0297fc, #1184d0)",
                }
              : { backgroundColor: "#888888" }
          }
        >
          Buy for {moveData.cost} $Brawl
        </button>
      )}
    </div>
  );
}
