// React
import { useState } from "react";

// CSS
import "./create-user-ui.css";

interface CreateUserProps {
  setActiveComponent: any;
  email: string;
  setEmail: any;
  password: string;
  setPassword: any;
}

export default function CreateUserUI({
  setActiveComponent,
  email,
  setEmail,
  password,
  setPassword,
}: CreateUserProps) {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [warningMessage, setWarningMessage] = useState(
    "Passwords do not match."
  );
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const GoToAccountSetup = () => {
    if (password === confirmPassword && password !== "") {
      setActiveComponent("setupaccount");
      setShowWarningMessage(false);
    } else if (password !== confirmPassword) {
      setWarningMessage("Passwords do not match.");
      setShowWarningMessage(true);
    } else if (password === "") {
      setWarningMessage("Password cannot be blank.");
      setShowWarningMessage(true);
    }
  };

  return (
    <div>
      <h2 className="create-user-title">CREATE A BRAWLCLUB ACCOUNT.</h2>
      <div className="form-container">
        <div>
          <label className="form-label">Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="name@example.com"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          ></input>
        </div>
        <div>
          <label className="form-label input-margin">Password</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          ></input>
          <label className="form-label input-margin">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
            }}
          ></input>
        </div>
      </div>
      <div>
        {showWarningMessage ? (
          <p className="create-user-warning-txt">{warningMessage}</p>
        ) : null}
      </div>

      <div>
        <button
          onClick={() => {
            GoToAccountSetup();
          }}
          type="button"
          className="create-user-btn"
        >
          Create Account
        </button>
        <p className="switch-to-sign-in-txt">
          Already have a BrawlClub account?
          <button
            onClick={() => {
              setActiveComponent("signin");
            }}
            type="button"
            className="switch-to-signin-btn"
          >
            Sign in to your account.
          </button>
        </p>
      </div>
    </div>
  );
}
