// Settings Context
import { useSettings } from "../../../settingsContext";

// CSS
import "./account-overview.css";

export default function AccountOverview() {
  const { userDocRef } = useSettings();

  return (
    <div className="account-overview-div">
      <h1 className="account-overview-username">{userDocRef.username}</h1>
      <img
        src={userDocRef.profilePic}
        className="account-overview-profile-pic-img"
      ></img>
      <div className="account-overview-stat-container">
        <h6 className="account-overview-stat-title-txt">Twitter:</h6>
        <h3 className="account-overview-stat-value-txt">
          {userDocRef.twitter !== "" ? userDocRef.twitter : "N/A"}
        </h3>
      </div>
      <div className="account-overview-stat-container">
        <h6 className="account-overview-stat-title-txt">Bio:</h6>
        <h3 className="account-overview-stat-value-txt">
          {userDocRef.bio !== "" ? userDocRef.bio : "N/A"}
        </h3>
      </div>
      <div className="account-overview-stat-container">
        <h6 className="account-overview-stat-title-txt">$Brawl:</h6>
        <h3 className="account-overview-stat-value-txt">{userDocRef.$Brawl}</h3>
      </div>
      <div className="account-overview-stat-container">
        <h6 className="account-overview-stat-title-txt">W/L/T:</h6>
        <h3 className="account-overview-stat-value-txt">
          {userDocRef.wins}/{userDocRef.losses}/{userDocRef.ties}
        </h3>
      </div>
      <div className="account-overview-stat-container">
        <h6 className="account-overview-stat-title-txt">Brawls Fought:</h6>
        <h3 className="account-overview-stat-value-txt">
          {userDocRef.wins + userDocRef.losses + userDocRef.ties}
        </h3>
      </div>
    </div>
  );
}
