// React
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Constants
import {
  brawlListingsTableName,
  errorBtnColorStyle,
  normalBtnColorStyle,
  successBtnColorStyle,
} from "../../../Constants";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Firebase
import { DeleteDocumentFromFirestore } from "../../../firebaseconfig";

// CSS
import "./open-brawl-item.css";

interface MatchListItemProps {
  rawBrawlData: any;
}

export default function OpenBrawlItem({ rawBrawlData }: MatchListItemProps) {
  const {
    userDocRef,
    brawlActive,
    setCurrentBrawlRef,
    setTransitionActive,
    setBrawlActive,
  } = useSettings();

  // Decides if the button should show an error, success or normal message
  const [btnMode, setBtnMode] = useState("normal");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const brawlData = rawBrawlData.data();
  brawlData["docId"] = rawBrawlData.ref.id;

  const AcceptBrawl = async () => {
    if (
      userDocRef.userID !== brawlData.player1UserID &&
      userDocRef.health > 0
    ) {
      setCurrentBrawlRef(brawlData);
      setBrawlActive(true);

      // Delete the brawl listing from the database
      await DeleteDocumentFromFirestore(
        brawlListingsTableName,
        brawlData.docId
      );

      setBtnMode("success");
      setTransitionActive(true);

      setTimeout(() => {
        setTransitionActive(false);
        navigate("/brawl");
      }, 1000);
    } else if (userDocRef.userID === brawlData.player1UserID) {
      setBtnMode("error");
      setErrorMessage("You");

      setTimeout(() => {
        setBtnMode("normal");
      }, 3000);
    } else if (userDocRef.health <= 0) {
      setBtnMode("error");
      setErrorMessage("Low HP");

      setTimeout(() => {
        setBtnMode("normal");
      }, 3000);
    }
  };

  return (
    <div className="open-brawl-item-div">
      <div className="open-brawl-item-profile-container">
        <img
          src={brawlData.player1ProfilePic}
          className="open-brawl-item-profile-img"
        ></img>
        <h6 className="open-brawl-item-profile-txt">
          {brawlData.player1Username}
        </h6>
      </div>
      <div className="open-brawl-item-wager-container">
        <h4 className="open-brawl-item-wager-txt">
          {brawlData.wagerAmount} $BRAWL
        </h4>
      </div>

      <button
        onClick={async () => {
          await AcceptBrawl();
        }}
        disabled={brawlActive}
        className="open-brawl-item-accept-btn"
        style={
          btnMode === "normal"
            ? normalBtnColorStyle
            : btnMode === "error"
            ? errorBtnColorStyle
            : btnMode === "success"
            ? successBtnColorStyle
            : normalBtnColorStyle
        }
      >
        {btnMode === "normal"
          ? "Accept"
          : btnMode === "success"
          ? "Accept"
          : btnMode === "error"
          ? errorMessage
          : "Accept"}
      </button>
    </div>
  );
}
