// React Components
import BrawlHistoryItem from "../Items/BrawlHistoryItem";

// Constants
import { maxBrawlHistory, userTableName } from "../../../Constants";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Firebase
import { UpdateDocumentOnFirestore } from "../../../firebaseconfig";

// CSS
import "./brawl-history.css";

export default function BrawlHistory() {
  const { userDocRef } = useSettings();

  const ClearHistory = async () => {
    await UpdateDocumentOnFirestore(userTableName, userDocRef.userID, {
      brawlHistory: [],
    });
  };

  return (
    <div>
      <div className="brawl-history-div">
        <div className="brawl-history-title-container">
          <h4 className="brawl-history-title-txt">Brawl History</h4>
        </div>
        <div className="brawl-history-item-container">
          {userDocRef.brawlHistory && userDocRef.brawlHistory.length > 0 ? (
            userDocRef.brawlHistory.map((result: any, index: number) => (
              <BrawlHistoryItem
                key={index}
                brawlDetails={result}
                index={index}
              />
            ))
          ) : (
            <h5 className="brawl-history-empty-txt">
              Your brawl history is empty
            </h5>
          )}
        </div>
      </div>
      <div className="open-brawls-active-container">
        <div className="open-brawls-active-body">
          <div className="open-brawls-active-details">
            <div className="open-brawls-active-count-txt">
              {userDocRef.brawlHistory.length}
              <span style={{ fontSize: "24px" }}>
                /{maxBrawlHistory} Brawls
              </span>
            </div>
          </div>
          <button
            onClick={async () => {
              await ClearHistory();
            }}
            className="open-brawls-active-remove-btn"
          >
            Clear History
          </button>
        </div>
      </div>
    </div>
  );
}
