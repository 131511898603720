// React
import { useState } from "react";

// React Components
import CreateBrawl from "./Tabs/CreateBrawl";
import BrawlHistory from "./Tabs/BrawlHistory";
import OpenBrawls from "./Tabs/OpenBrawls";

// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import {
  brawlHistoryTabImg,
  createBrawlTabImg,
  openBrawlsTabImg,
} from "../../Constants";

// CSS
import "./brawl-popout.css";

export default function BrawlPopout() {
  const [activeTab, setActiveTab] = useState("brawls");

  const { showBrawlPopout, setShowBrawlPopout } = useSettings();

  return (
    <div className={`brawl-popout-div ${showBrawlPopout ? "open" : "close"}`}>
      <div className="brawl-popout-tab-container">
        <button
          onClick={() => {
            setShowBrawlPopout(false);
          }}
          className="brawl-popout-close-btn"
        >
          <b>X</b>
        </button>
        <button
          onClick={() => {
            setActiveTab("brawls");
          }}
          className="brawl-popout-tab-btn"
          style={{
            borderTopLeftRadius: "1rem",
            borderBottomLeftRadius: "1rem",
          }}
        >
          <div className="brawl-popout-tab-overlay" />
          <img src={openBrawlsTabImg} className="brawl-popout-tab-img"></img>
          <p className="brawl-popout-tab-txt">Brawls</p>
        </button>
        <button
          onClick={() => {
            setActiveTab("create");
          }}
          className="brawl-popout-tab-btn"
        >
          <div className="brawl-popout-tab-overlay" />
          <img src={createBrawlTabImg} className="brawl-popout-tab-img"></img>
          <p className="brawl-popout-tab-txt">Create</p>
        </button>
        <button
          onClick={() => {
            setActiveTab("history");
          }}
          className="brawl-popout-tab-btn"
          style={{
            borderTopRightRadius: "1rem",
            borderBottomRightRadius: "1rem",
          }}
        >
          <div className="brawl-popout-tab-overlay" />
          <img src={brawlHistoryTabImg} className="brawl-popout-tab-img"></img>
          <p className="brawl-popout-tab-txt">History</p>
        </button>
      </div>
      {activeTab === "brawls" ? (
        <div>
          <OpenBrawls />
        </div>
      ) : activeTab === "create" ? (
        <div>
          <CreateBrawl />
        </div>
      ) : activeTab === "history" ? (
        <div>
          <BrawlHistory />
        </div>
      ) : null}
    </div>
  );
}
