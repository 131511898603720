// React
import { useState } from "react";

// React Components
import QuestsList from "./Tabs/QuestsList";

// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import { createBrawlTabImg, openBrawlsTabImg } from "../../Constants";

// CSS
import "./quest-popout.css";

export default function QuestsPopout() {
  const { showQuestsPopout, setShowQuestsPopout } = useSettings();

  const [activeTab, setActiveTab] = useState("questlines");

  return (
    <div className={`quest-popout-div ${showQuestsPopout ? "open" : "close"}`}>
      <div className="quest-popout-tab-container">
        <button
          onClick={() => {
            setShowQuestsPopout(false);
          }}
          className="quest-popout-close-btn"
        >
          <b>X</b>
        </button>
        <button
          onClick={() => {
            setActiveTab("questlines");
          }}
          className="quest-popout-tab-btn"
          style={{
            borderTopLeftRadius: "1rem",
            borderBottomLeftRadius: "1rem",
          }}
        >
          <div className="quest-popout-tab-overlay" />
          <img src={openBrawlsTabImg} className="quest-popout-tab-img"></img>
          <p className="quest-popout-tab-txt">Questlines</p>
        </button>
        <button
          onClick={() => {
            setActiveTab("weekly");
          }}
          className="quest-popout-tab-btn"
          style={{
            borderTopRightRadius: "1rem",
            borderBottomRightRadius: "1rem",
          }}
        >
          <div className="quest-popout-tab-overlay" />
          <img src={createBrawlTabImg} className="quest-popout-tab-img"></img>
          <p className="quest-popout-tab-txt">Weekly</p>
        </button>
      </div>
      {activeTab === "questlines" ? (
        <QuestsList type={"questlines"} />
      ) : activeTab === "weekly" ? (
        <QuestsList type={"weekly"} />
      ) : null}
    </div>
  );
}
