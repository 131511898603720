import React, { createContext, useContext, useState } from "react";

// Define the type for your context value
interface SettingsContextType {
  userDocRef: any;
  currentBrawlRef: any;
  openBrawls: any;
  showQuestsPopout: boolean;
  showBrawlPopout: boolean;
  showHospitalPopout: boolean;
  showBankPopout: boolean;
  showLeaderboardPopout: boolean;
  showMarketplaceMapover: boolean;
  showAccountMapover: boolean;
  transitionActive: boolean;
  brawlActive: boolean;
  setUserDocRef: (ref: any) => void;
  setCurrentBrawlRef: (ref: any) => void;
  setOpenBrawls: (ref: any) => void;
  setShowQuestsPopout: (show: boolean) => void;
  setShowBrawlPopout: (show: boolean) => void;
  setShowHospitalPopout: (show: boolean) => void;
  setShowBankPopout: (show: boolean) => void;
  setShowLeaderboardPopout: (show: boolean) => void;
  setShowMarketplaceMapover: (show: boolean) => void;
  setShowAccountMapover: (show: boolean) => void;
  setTransitionActive: (active: boolean) => void;
  setBrawlActive: (active: boolean) => void;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userDocRef, setUserDocRef] = useState<any>();
  const [currentBrawlRef, setCurrentBrawlRef] = useState<any>();
  const [openBrawls, setOpenBrawls] = useState<any[]>([]);
  const [showQuestsPopout, setShowQuestsPopout] = useState(false);
  const [showBrawlPopout, setShowBrawlPopout] = useState(false);
  const [showHospitalPopout, setShowHospitalPopout] = useState(false);
  const [showBankPopout, setShowBankPopout] = useState(false);
  const [showLeaderboardPopout, setShowLeaderboardPopout] = useState(false);
  const [showMarketplaceMapover, setShowMarketplaceMapover] = useState(false);
  const [showAccountMapover, setShowAccountMapover] = useState(false);
  const [transitionActive, setTransitionActive] = useState(false);
  const [brawlActive, setBrawlActive] = useState(false);

  return (
    <SettingsContext.Provider
      value={{
        userDocRef,
        currentBrawlRef,
        openBrawls,
        showQuestsPopout,
        showBrawlPopout,
        showHospitalPopout,
        showBankPopout,
        showLeaderboardPopout,
        showMarketplaceMapover,
        showAccountMapover,
        transitionActive,
        brawlActive,
        setUserDocRef,
        setCurrentBrawlRef,
        setOpenBrawls,
        setShowQuestsPopout,
        setShowBrawlPopout,
        setShowHospitalPopout,
        setShowBankPopout,
        setShowLeaderboardPopout,
        setShowMarketplaceMapover,
        setShowAccountMapover,
        setTransitionActive,
        setBrawlActive,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
