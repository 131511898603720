// Constants
import { armourColor, weaponColor } from "../../../Constants";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Global Functions
import { getStatValue, getRarityColor } from "../../../globalFunctions";

// CSS
import "./bank-item.css";

interface RarityItemProps {
  itemName: string;
  itemCollection: string;
  itemIcon: any;
  itemRarity: string;
  owned: any;
}

export default function RarityItem({
  itemName,
  itemCollection,
  itemIcon,
  itemRarity,
  owned,
}: RarityItemProps) {
  const { userDocRef } = useSettings();

  return (
    <button className="bank-item-btn">
      <div
        className="bank-item-img-container"
        style={{
          backgroundColor: getRarityColor(itemRarity),
        }}
      >
        <img src={itemIcon} className="bank-item-img"></img>
        <div
          className="bank-item-stat-container"
          style={
            itemName === "weapon"
              ? { backgroundColor: weaponColor }
              : { backgroundColor: armourColor }
          }
        >
          <p className="bank-item-img-stat-txt">
            +
            {owned === true || owned === false
              ? `${getStatValue(itemRarity)} ${
                  itemName === "weapon" ? "Damage" : "Health"
                }`
              : "???"}
          </p>
        </div>
      </div>
      <div className="bank-item-details-container">
        <h4 className="bank-item-slot-txt">
          {itemRarity.charAt(0).toUpperCase() + itemRarity.slice(1)}
        </h4>

        <p className="bank-item-collection-txt">
          {userDocRef.inventory[itemName] &&
          userDocRef.inventory[itemName][itemCollection.toLowerCase()]
            ? itemCollection
            : "???"}
        </p>

        <p className="bank-item-rarity-txt">
          Owned: {owned === true ? "Yes" : owned === false ? "No" : "No"}
        </p>
      </div>
    </button>
  );
}
