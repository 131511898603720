// React
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// React Components
import TransitionScreen from "../components/TransitionScreen/TransitionScreen";
import BrawlVSScreen from "../components/BrawlVisuals/Screens/vsScreen/BrawlVSScreen";
import MoveSelectScreen from "../components/BrawlVisuals/Screens/moveSelectScreen/MoveSelectScreen";
import BrawlingScreen from "../components/BrawlVisuals/Screens/brawlingScreen/BrawlingScreen";
import BrawlOverviewPopup from "../components/BrawlVisuals/OverviewPopup/BrawlOverviewPopup";
import ForfeitPopup from "../components/BrawlVisuals/ForfeitPopup/ForfeitPopup";

// Constants
import {
  SpecialMoveKeys,
  basicMoves,
  brawlTime,
  brawlTimeAnimationOffset,
  buffs,
  debuffs,
  itemCategories,
  itemDropChance,
  itemPool,
  itemRarities,
  movesTime,
  movesTimeAnimationOffset,
  specialMoves,
  staticQuestData,
  userTableName,
  vsTime,
  vsTimeAnimationOffset,
} from "../Constants";

// Global Functions
import { getStatValue, toCamelCase } from "../globalFunctions";

// Settings Context
import { useSettings } from "../settingsContext";

// Firebase
import {
  GetDataFromFirestore,
  UpdateDocumentOnFirestore,
} from "../firebaseconfig";
import { arrayUnion, increment } from "firebase/firestore";

// CSS
import "./brawl-visuals-page.css";

export default function BrawlVisualsPage() {
  const {
    userDocRef,
    currentBrawlRef,
    transitionActive,
    setTransitionActive,
    setBrawlActive,
  } = useSettings();

  // Visual progression
  const [showVSScreen, setShowVSScreen] = useState(false);
  const [showMoveSelectScreen, setShowMoveSelectScreen] = useState(false);
  const [showBrawlingScreen, setShowBrawlingScreen] = useState(false);
  const [showOverviewPopup, setShowOverviewPopup] = useState(false);

  // Forfeiting
  const [showForfeitPopup, setShowForfeitPopup] = useState(false);
  const [forfeiting, setForfeiting] = useState(false);

  // Scores
  const [damageTaken, setDamageTaken] = useState({ player1: 0, player2: 0 });

  // Round related
  const [roundLogs, setRoundLogs] = useState<any>([]);
  const [overviewMessage, setOverviewMessage] = useState<string>("");
  const [roundTimer, setRoundTimer] = useState<number>(0);
  const [playerWon, setPlayerWon] = useState<string>("");
  const playerWonRef = useRef<string>(playerWon);

  // Health related
  const [playerStatus, setPlayerStatus] = useState({
    player1: {
      health: currentBrawlRef.player1Health,
      maxHealth: currentBrawlRef.player1MaxHealth,
      debuffs: { name: "null", turnsRemaining: 0 },
      buffs: { name: "null", turnsRemaining: 0 },
    },
    player2: {
      health: currentBrawlRef.live
        ? userDocRef.health
        : currentBrawlRef.player2Health,
      maxHealth: currentBrawlRef.live
        ? userDocRef.maxHealth
        : currentBrawlRef.player2MaxHealth,
      debuffs: { name: "null", turnsRemaining: 0 },
      buffs: { name: "null", turnsRemaining: 0 },
    },
  });
  const [healthSnapshot, setHealthSnapshot] = useState({
    player1: currentBrawlRef.player1Health,
    player2: currentBrawlRef.live
      ? userDocRef.health
      : currentBrawlRef.player2Health,
  });

  // Item related
  const [earnedItemData, setEarnedItemData] = useState({
    dupe: false,
    replace: false,
    equipmentUpdate: {},
    statUpdate: {},
    rarity: "null",
    collection: "null",
    type: "null",
  });

  // Live brawls
  const [selectedMoves, setSelectedMoves] = useState<string[]>([
    "???",
    "???",
    "???",
    "???",
    "???",
    "???",
  ]);
  const selectedMovesRef = useRef<string[]>(selectedMoves);

  // Navigation
  const navigate = useNavigate();

  // Use Effects
  useEffect(() => {
    setTransitionActive(false);
    setBrawlActive(false);

    if (!currentBrawlRef.live) {
      setEarnedItemData(currentBrawlRef.player1EarnedItem);
    }

    const RunBrawl = async () => {
      setDamageTaken({ player1: 0, player2: 0 });

      await ProgressBrawl();
    };

    RunBrawl();
  }, []);

  useEffect(() => {
    selectedMovesRef.current = selectedMoves;
  }, [selectedMoves]);

  useEffect(() => {
    playerWonRef.current = playerWon;
  }, [playerWon]);

  useEffect(() => {
    if (showOverviewPopup) {
      // Set a fake timeout to get the highest timeout id
      let highestTimeoutId = setTimeout(";");
      for (let i = 0; i < highestTimeoutId; i++) {
        clearTimeout(i);
      }
    }
  }, [showOverviewPopup]);

  const SkipBrawl = () => {
    const simulateMatch = () => {
      // Set damage taken values to 0
      damageTaken.player1 = 0;
      damageTaken.player2 = 0;

      // Set each players health to what they started with
      playerStatus.player1.health = healthSnapshot.player1;
      playerStatus.player2.health = healthSnapshot.player2;

      // Apply changes
      setDamageTaken(damageTaken);
      setPlayerStatus(playerStatus);

      // Simulate govern round for each round until a player has no health left
      for (let i = 0; i < 6; i++) {
        // if you start a brawl, you are player 1
        const player1Move = currentBrawlRef.player1Moves[i];
        // if you accept a brawl, you are player 2
        const player2Move = currentBrawlRef.live
          ? selectedMovesRef.current[i]
          : currentBrawlRef.player2Moves[i];

        // Calculates the damage done by both players, including by special moves, debuffs and buffs
        const damageValues = CalculateDamageAndEffects(
          player1Move,
          player2Move
        );

        // Set damage taken values
        damageTaken.player1 += damageValues.player2Damage;
        damageTaken.player2 += damageValues.player1Damage;
        setDamageTaken(damageTaken);

        // Decrement each players health
        playerStatus.player1.health -= damageValues.player2Damage;
        playerStatus.player2.health -= damageValues.player1Damage;
        setPlayerStatus(playerStatus);

        if (
          playerStatus.player1.health <= 0 ||
          playerStatus.player2.health <= 0
        ) {
          break;
        }
      }
    };

    simulateMatch();

    // Generates a new round log
    const message: string = GenerateRoundLogs(true);
    setOverviewMessage(message);

    setShowOverviewPopup(true);
  };

  const ForfeitBrawl = async () => {
    setForfeiting(true);
    await PickWinner(true);
    setTransitionActive(true);

    // Generates a new round log
    const message: string = GenerateRoundLogs(true);
    setOverviewMessage(message);

    setTimeout(() => {
      setTransitionActive(false);
      navigate("/home");
    }, 1000);
  };

  // Visual progression
  const StartVSScreenLogic = (roundTime: number, animationOffset: number) => {
    let counter = 0;

    setRoundTimer(roundTime + animationOffset);

    DecrementRoundTimer(counter, roundTime, animationOffset);
  };

  const StartMoveScreenLogic = (roundTime: number, animationOffset: number) => {
    let counter = 0;

    DecrementRoundTimer(counter, roundTime, animationOffset);
  };

  const StartBrawlingScreenLogic = (
    roundTime: number,
    animationOffset: number
  ) => {
    const timeBetweenMoves = roundTime / 6;
    let currentRound = 1;
    let counter = 0;

    setRoundTimer(roundTime);

    DecrementRoundTimer(counter, roundTime, animationOffset);

    setTimeout(() => {
      repeatGovernRound(currentRound, 6, timeBetweenMoves * 1000);
    }, timeBetweenMoves * 1000);

    setTimeout(() => {
      // Generates a new round log
      const message: string = GenerateRoundLogs(true);
      setOverviewMessage(message);

      setShowOverviewPopup(true);
    }, 7 * timeBetweenMoves * 1000);
  };

  const DecrementRoundTimer = (
    counter: number,
    roundTime: number,
    animationOffset: number
  ) => {
    setRoundTimer((prevRoundTimer) => prevRoundTimer - 1);
    counter++;

    if (counter >= roundTime + animationOffset) {
    } else {
      setTimeout(() => {
        DecrementRoundTimer(counter, roundTime, animationOffset);
      }, 1000);
    }
  };

  const repeatGovernRound = (round: any, times: number, delay: number) => {
    if (times <= 0) return;

    GovernRound(round);
    round++;
    setTimeout(() => repeatGovernRound(round, times - 1, delay), delay);
  };

  const AutoPickJabs = () => {
    for (let i = 0; i < selectedMovesRef.current.length; i++) {
      if (selectedMovesRef.current[i] === "???") {
        selectedMovesRef.current[i] = "jab";
      }
    }
  };

  // Game logic
  const CalculateDamageAndEffects = (
    player1Move: string,
    player2Move: string
  ) => {
    const player1WeaponDamage = getStatValue(
      currentBrawlRef.player1WeaponRarity
    );
    const player2WeaponDamage = getStatValue(
      currentBrawlRef.live
        ? userDocRef.equippedItems.weapon.itemRarity
        : currentBrawlRef.player2WeaponRarity
    );

    const moves = [
      { ...basicMoves[player1Move] },
      { ...basicMoves[player2Move] },
    ];

    moves.map((move, index) => {
      const otherMove = moves[(index + 1) % moves.length]; // Gets the other item
      const extraDamage =
        index === 0 ? player1WeaponDamage : player2WeaponDamage;

      if (move.type === "atk") {
        move.value += extraDamage;
      } else if (move.type === "blk") {
        // Remove 2 from the opponent's damage
        otherMove.value -= move.value;

        // Remove 2 from your own damage, as the block amount is counted as damage
        move.value -= move.value;
      } else if (move.type === "special") {
        let specialMove =
          index === 0
            ? currentBrawlRef.live
              ? userDocRef.specialMove
              : currentBrawlRef.player2SpecialMove
            : currentBrawlRef.player1SpecialMove;

        if (specialMove === "counter") {
          if (otherMove.type === "special") {
            specialMove =
              index === 0
                ? currentBrawlRef.player1SpecialMove
                : currentBrawlRef.live
                ? userDocRef.specialMove
                : currentBrawlRef.player2SpecialMove;
          } else {
            if (otherMove.name === "jab" || otherMove.name === "uppercut") {
              move.value = move.value + extraDamage;
            } else if (otherMove.name === "block") {
              // Remove 2 from the opponent's damage
              otherMove.value -= basicMoves.block.value;

              // Remove 2 from your own damage, as the block amount is counted as damage
              move.value -= basicMoves.block.value;
            }
          }
        }

        const specialMoveStats =
          specialMoves[toCamelCase(specialMove) as keyof typeof specialMoves]
            .stats;

        move.value += specialMoveStats.enemyDamage + extraDamage;
        otherMove.value += specialMoveStats.selfDamage;
        otherMove.value -= specialMoveStats.damageToBlock;

        const player =
          index === 0 ? playerStatus.player1 : playerStatus.player2;

        const otherPlayer =
          index === 0 ? playerStatus.player2 : playerStatus.player1;

        // Heal player 2 for 4 health
        player.health += specialMoves.heal;

        // Ensure health does not exceed maxHealth
        if (player.health > player.maxHealth) {
          player.health = player.maxHealth;
        }

        if (otherPlayer.debuffs.name !== "null") {
          move.value +=
            debuffs[
              otherPlayer.debuffs.name as keyof typeof debuffs
            ].damagePerTurn;

          otherPlayer.debuffs.turnsRemaining -= 1;

          if (otherPlayer.debuffs.turnsRemaining <= 0) {
            otherPlayer.debuffs.name = "null";
            otherPlayer.debuffs.turnsRemaining = 0;
          }
        }

        if (player.buffs.name !== "null") {
          move.value *=
            buffs[player.buffs.name as keyof typeof buffs].damageMultiplier;

          player.buffs.turnsRemaining -= 1;

          if (player.buffs.turnsRemaining <= 0) {
            player.buffs.name = "null";
            player.buffs.turnsRemaining = 0;
          }
        }

        const specialMoveDebuff =
          specialMoves[toCamelCase(specialMove) as keyof typeof specialMoves]
            .debuff;

        const specialMoveBuff =
          specialMoves[toCamelCase(specialMove) as keyof typeof specialMoves]
            .buff;

        if (specialMoveDebuff && "name" in specialMoveDebuff) {
          otherPlayer.debuffs = {
            name: specialMoveDebuff.name,
            turnsRemaining: specialMoveDebuff.turnDuration,
          };
        }

        if (specialMoveBuff && "name" in specialMoveBuff) {
          player.buffs = {
            name: specialMoveBuff.name,
            turnsRemaining: specialMoveBuff.turnDuration,
          };
        }
      }
    });

    if (moves[0].value < 0) {
      moves[0].value = 0;
    }

    if (moves[1].value < 0) {
      moves[1].value = 0;
    }

    setPlayerStatus(playerStatus);

    return {
      player1Damage: moves[0].value,
      player2Damage: moves[1].value,
    };
  };

  const FindActiveQuests = async (
    questTypes: string[],
    client: boolean,
    player1Data: any
  ) => {
    let questUpdates: any = {};

    for (const quest of questTypes) {
      if (client) {
        const brawlQuests = userDocRef.questLines[quest];
        const selectedQuest = brawlQuests.find(
          (questData: any) => !questData.claimed
        );

        if (
          selectedQuest &&
          selectedQuest.progress !==
            staticQuestData.startBrawls[brawlQuests.indexOf(selectedQuest)]
              .requirement
        ) {
          const questIndex =
            userDocRef.questLines[quest].indexOf(selectedQuest);
          userDocRef.questLines[quest][questIndex].progress += 1;

          questUpdates = {
            ...questUpdates,
            [`questLines.${quest}`]: userDocRef.questLines[quest],
          };
        }
      } else {
        if (player1Data) {
          const brawlQuests = player1Data.questLines[quest];

          const selectedQuest = brawlQuests.find(
            (questData: any) => !questData.claimed
          );

          if (
            selectedQuest &&
            selectedQuest.progress !==
              staticQuestData.startBrawls[brawlQuests.indexOf(selectedQuest)]
                .requirement
          ) {
            const questIndex =
              player1Data.questLines[quest].indexOf(selectedQuest);
            player1Data.questLines[quest][questIndex].progress += 1;

            questUpdates = {
              ...questUpdates,
              [`questLines.${quest}`]: player1Data.questLines[quest],
            };
          }
        }
      }
    }

    return questUpdates;
  };

  const GenerateAndEquipItemReward = (isPlayer1: boolean, player1Data: any) => {
    // Sub Functions
    function calculateItemRarity(
      itemDropChance: number,
      itemCategory: string
    ): string {
      const dropChance = itemDropChance * 100; // Convert the drop chance to a percentage
      const chanceType =
        itemCategory === "weapon" ? "weaponDropChance" : "armourDropChance";
      let rarity: string = "default";

      // Check the rarity based on the drop chance
      if (dropChance < itemRarities.legendary[chanceType]) {
        rarity = rarities[4].name;
      } else if (dropChance < itemRarities.epic[chanceType]) {
        rarity = rarities[3].name;
      } else if (dropChance < itemRarities.rare[chanceType]) {
        rarity = rarities[2].name;
      } else if (dropChance < itemRarities.uncommon[chanceType]) {
        rarity = rarities[1].name;
      } else if (dropChance < 100) {
        rarity = rarities[0].name;
      }

      return rarity;
    }

    // Variables
    const rarities: any = [];
    let generatedItem;

    for (const key in itemRarities) {
      const dictKey = key as keyof typeof itemRarities;
      if (itemRarities[dictKey].name !== "default") {
        rarities.push({
          name: itemRarities[dictKey].name,
          weaponDropChance: itemRarities[dictKey].weaponDropChance,
          armourDropChance: itemRarities[dictKey].armourDropChance,
        });
      }
    }

    // Does a % check to see if the player will earn an item or not
    const itemEarned = Math.random() < itemDropChance;
    if (!itemEarned) {
      return;
    }

    // Randomly picks the item to be awarded
    const itemCategory =
      itemCategories[Math.floor(Math.random() * itemCategories.length)];

    // If the item doesn't exist, exit the function
    if (!itemPool[itemCategory]) {
      return;
    }

    // Calculates the chances for the picked item's rarity
    const rarityDropPercentage = Math.random();
    const itemRarity = calculateItemRarity(rarityDropPercentage, itemCategory);

    const collections = itemPool[itemCategory];
    const selectedCollection =
      collections[Math.floor(Math.random() * collections.length)]
        .collectionName;

    generatedItem = {
      type: itemCategory,
      rarity: itemRarity,
      collection: selectedCollection,
    };

    // If an item was generated
    if (generatedItem) {
      let currentItem;
      let equipmentUpdate = {};
      let statUpdate = {};
      let replaceItem: boolean = false;
      let playerEquippedItems;
      let itemDupe: boolean = false;

      if (isPlayer1) {
        currentItem = player1Data.equippedItems[generatedItem.type].itemRarity;
        playerEquippedItems = player1Data.equippedItems;

        if (
          player1Data.inventory[generatedItem.type][generatedItem.collection]
        ) {
          itemDupe =
            player1Data.inventory[generatedItem.type][generatedItem.collection][
              generatedItem.rarity
            ];
        } else {
          itemDupe = false;
        }
      } else {
        currentItem = userDocRef.equippedItems[generatedItem.type].itemRarity;
        playerEquippedItems = userDocRef.equippedItems;

        if (
          userDocRef.inventory[generatedItem.type][generatedItem.collection]
        ) {
          itemDupe =
            userDocRef.inventory[generatedItem.type][generatedItem.collection][
              generatedItem.rarity
            ];
        } else {
          itemDupe = false;
        }
      }

      // Get the rarity of the currently equipped item
      const currentItemStats =
        itemRarities[currentItem as keyof typeof itemRarities].statValue;

      const newItemStats =
        itemRarities[generatedItem.rarity as keyof typeof itemRarities]
          .statValue;

      // Check if the new item is of a better rarity and then currently equipped item
      if (newItemStats > currentItemStats) {
        replaceItem = true;
        equipmentUpdate = {
          [`equippedItems.${generatedItem.type}`]: {
            collection: generatedItem.collection,
            itemName: "",
            itemRarity: generatedItem.rarity,
          },
        };

        if (generatedItem.type !== "weapon") {
          const { helmet, chest, gloves, pants, boots } = playerEquippedItems;

          let extraHealth =
            itemRarities[helmet.itemRarity as keyof typeof itemRarities]
              .statValue +
            itemRarities[chest.itemRarity as keyof typeof itemRarities]
              .statValue +
            itemRarities[gloves.itemRarity as keyof typeof itemRarities]
              .statValue +
            itemRarities[pants.itemRarity as keyof typeof itemRarities]
              .statValue +
            itemRarities[boots.itemRarity as keyof typeof itemRarities]
              .statValue;

          // Subtract the rarity index of the item being replaced
          extraHealth -= currentItemStats;
          extraHealth += newItemStats;

          // Update the user's max health to reflect the new item
          statUpdate = {
            maxHealth: 100 + extraHealth,
          };
        }
      }

      return {
        dupe: itemDupe,
        replace: replaceItem,
        equipmentUpdate: equipmentUpdate,
        statUpdate: statUpdate,
        rarity: generatedItem.rarity,
        collection: generatedItem.collection,
        type: itemCategory,
      };
    } else {
      return {
        dupe: false,
        replace: false,
        equipmentUpdate: {},
        statUpdate: {},
        rarity: "",
        collection: "",
        type: "",
      };
    }
  };

  const PickWinner = async (forfeit: boolean) => {
    let instantScoreboard = { player1: 0, player2: 0 };
    let questUpdates: any;
    let opponentDataUpdate: any;
    let userDataUpdate: any;
    let opponentEarnedItem: boolean = false;
    let earnedItem: any = {};

    // Update the damage taken to find a winner or see if it's a tie
    for (let i = 0; i < 6; i++) {
      const result = CalculateDamageAndEffects(
        currentBrawlRef.player1Moves[i],
        currentBrawlRef.live
          ? selectedMovesRef.current[i]
          : currentBrawlRef.player2Moves[i]
      );

      if (!forfeit) {
        instantScoreboard.player1 += result.player2Damage;

        currentBrawlRef.player1Health -= result.player2Damage;

        if (currentBrawlRef.player1Health <= 0) {
          instantScoreboard.player1 = 999;
          break;
        }
      }

      instantScoreboard.player2 += result.player1Damage;

      if (currentBrawlRef.live) {
        userDocRef.health -= result.player1Damage;

        if (userDocRef.health <= 0) {
          instantScoreboard.player2 = 999;
          break;
        }
      } else {
        currentBrawlRef.player2Health -= result.player1Damage;

        if (currentBrawlRef.player2Health <= 0) {
          instantScoreboard.player2 = 999;
          break;
        }
      }
    }

    // If the brawl is not live
    if (!currentBrawlRef.live) {
      if (instantScoreboard.player1 === instantScoreboard.player2) {
        setPlayerWon("tie");
      } else if (instantScoreboard.player1 < instantScoreboard.player2) {
        setPlayerWon("win");
      } else if (instantScoreboard.player1 > instantScoreboard.player2) {
        setPlayerWon("loss");
      }

      // If the brawl is live
    } else {
      if (instantScoreboard.player1 === instantScoreboard.player2) {
        setPlayerWon("tie");

        const player1Data = await GetDataFromFirestore(
          userTableName,
          currentBrawlRef?.player1UserID
        );

        // Quests for the opponent
        questUpdates = await FindActiveQuests(
          ["playBrawls"],
          false,
          player1Data
        );

        // Opponents data to be updated
        opponentDataUpdate = {
          health:
            currentBrawlRef.player1Health >= 0
              ? currentBrawlRef.player1Health
              : 0,
          $Brawl: increment(currentBrawlRef.wagerAmount),
          ties: increment(1),
          ...questUpdates,
        };

        // Quests for you
        questUpdates = await FindActiveQuests(
          ["playBrawls"],
          true,
          player1Data
        );

        userDataUpdate = {
          health: userDocRef.health >= 0 ? userDocRef.health : 0,
          ties: increment(1),
          ...questUpdates,
        };
      }

      // If you took more damage than the opponent did
      else if (instantScoreboard.player1 < instantScoreboard.player2) {
        let brawlFromDupe: number = 0;

        setPlayerWon(currentBrawlRef.live ? "loss" : "win");

        const player1Data = await GetDataFromFirestore(
          userTableName,
          currentBrawlRef?.player1UserID
        );

        // Randomly pick an item. There is a 50% chance nothing will be picked.
        const reward = GenerateAndEquipItemReward(true, player1Data);

        // If a reward was picked
        if (reward != null) {
          setEarnedItemData(reward);
          earnedItem = reward;
          opponentEarnedItem = true;

          // Create a new collection
          const newCollection = {
            legendary: false,
            epic: false,
            rare: false,
            uncommon: false,
            common: false,
          };

          if (
            userDocRef.inventory[reward.type] &&
            userDocRef.inventory[reward.type][reward.collection]
          ) {
            // Set the item collection to claimed at the relevant rarity
            userDocRef.inventory[reward.type][reward.collection][
              reward.rarity
            ] = true;
          } else {
            // Set the item collection to claimed at the relevant rarity
            newCollection[reward.rarity as keyof typeof newCollection] = true;
          }

          // Set the data to be updated on firestore
          const newItem =
            userDocRef.inventory[reward.type] &&
            userDocRef.inventory[reward.type][reward.collection]
              ? userDocRef.inventory[reward.type][reward.collection]
              : newCollection;

          opponentDataUpdate = {
            ...opponentDataUpdate,
            [`inventory.${reward.type}.${reward.collection}`]: newItem,
          };

          // If the item is a better rarity than what's currently equipped, update the stats and equipped items
          if (reward.replace) {
            opponentDataUpdate = {
              ...opponentDataUpdate,
              ...reward.statUpdate,
              ...reward.equipmentUpdate,
            };
          } else if (reward.dupe) {
            brawlFromDupe =
              itemRarities[reward.rarity as keyof typeof itemRarities]
                .duplicateReward;
          }
        }

        // Quests for the opponent
        questUpdates = await FindActiveQuests(
          ["playBrawls", "winBrawls"],
          false,
          player1Data
        );

        // Opponents data to be updated
        opponentDataUpdate = {
          ...opponentDataUpdate,
          health:
            currentBrawlRef.player1Health >= 0
              ? currentBrawlRef.player1Health
              : 0,
          $Brawl: increment(currentBrawlRef.wagerAmount * 2 + brawlFromDupe),
          wins: increment(1),
          ...questUpdates,
        };

        questUpdates = await FindActiveQuests(
          ["playBrawls"],
          true,
          player1Data
        );

        // Challenger's data to be updated
        userDataUpdate = {
          ...userDataUpdate,
          health: userDocRef.health >= 0 ? userDocRef.health : 0,
          $Brawl: increment(-currentBrawlRef.wagerAmount),
          losses: increment(1),
          ...questUpdates,
        };

        // If the opponent took more damage than you did
      } else if (instantScoreboard.player1 > instantScoreboard.player2) {
        let brawlFromDupe: number = 0;

        setPlayerWon(currentBrawlRef.live ? "win" : "loss");

        const player1Data = await GetDataFromFirestore(
          userTableName,
          currentBrawlRef?.player1UserID
        );

        // Randomly pick an item. There is a 50% chance nothing will be picked.
        const reward = GenerateAndEquipItemReward(false, player1Data);

        // If a reward was picked
        if (reward != null) {
          setEarnedItemData(reward);

          // Create a new collection
          const newCollection = {
            legendary: false,
            epic: false,
            rare: false,
            uncommon: false,
            common: false,
          };

          if (
            userDocRef.inventory[reward.type] &&
            userDocRef.inventory[reward.type][reward.collection]
          ) {
            // Set the item collection to claimed at the relevant rarity
            userDocRef.inventory[reward.type][reward.collection][
              reward.rarity
            ] = true;
          } else {
            // Set the item collection to claimed at the relevant rarity
            newCollection[reward.rarity as keyof typeof newCollection] = true;
          }

          // Set the data to be updated on firestore
          const newItem =
            userDocRef.inventory[reward.type] &&
            userDocRef.inventory[reward.type][reward.collection]
              ? userDocRef.inventory[reward.type][reward.collection]
              : newCollection;

          userDataUpdate = {
            ...userDataUpdate,
            [`inventory.${reward.type}.${reward.collection}`]: newItem,
          };

          // If the item is a better rarity than what's currently equipped, update the stats and equipped items
          if (reward.replace) {
            console.log("replace item");
            userDataUpdate = {
              ...userDataUpdate,
              ...reward.statUpdate,
              ...reward.equipmentUpdate,
            };
          } else if (reward.dupe) {
            console.log("Is dupe");
            brawlFromDupe =
              itemRarities[reward.rarity as keyof typeof itemRarities]
                .duplicateReward;
          }

          questUpdates = await FindActiveQuests(
            ["playBrawls", "winBrawls"],
            true,
            player1Data
          );

          userDataUpdate = {
            ...userDataUpdate,
            health: userDocRef.health >= 0 ? userDocRef.health : 0,
            $Brawl: increment(currentBrawlRef?.wagerAmount + brawlFromDupe),
            wins: increment(1),
            ...questUpdates,
          };

          questUpdates = await FindActiveQuests(
            ["playBrawls"],
            false,
            player1Data
          );

          opponentDataUpdate = {
            ...opponentDataUpdate,
            health:
              currentBrawlRef.player1Health >= 0
                ? currentBrawlRef.player1Health
                : 0,
            losses: increment(1),
            ...questUpdates,
          };
        }
      }

      opponentDataUpdate = {
        ...opponentDataUpdate,
        brawlHistory: arrayUnion({
          //// Player 2
          // - profile
          player2Username: userDocRef.username,
          player2ProfilePic: userDocRef.profilePic,
          player2UserID: userDocRef.userID,
          player2Wins: userDocRef.wins,
          player2Losses: userDocRef.losses,

          // - status
          player2Health: playerStatus.player2.health,
          player2MaxHealth: playerStatus.player2.maxHealth,
          player2WeaponRarity: userDocRef.equippedItems.weapon.itemRarity,

          // - moves
          player2Moves: selectedMovesRef.current,
          player2SpecialMove: userDocRef.specialMove,

          //// Player 1
          // - moves
          player1Moves: currentBrawlRef.player1Moves,
          player1SpecialMove: currentBrawlRef.player1SpecialMove,

          // - status
          player1Health: playerStatus.player1.health,
          player1MaxHealth: playerStatus.player1.maxHealth,
          player1WeaponRarity: currentBrawlRef.player1WeaponRarity,
          player1EarnedItem: opponentEarnedItem
            ? earnedItem
            : {
                dupe: false,
                replace: false,
                equipmentUpdate: {},
                statUpdate: {},
                rarity: "null",
                collection: "null",
                type: "null",
              },

          //// General
          wagerAmount: currentBrawlRef.wagerAmount,
          live: false,
          forfeit: forfeit,
        }),
      };

      // Update the opponent's firestore document to reflect their win
      await UpdateDocumentOnFirestore(
        userTableName,
        currentBrawlRef.player1UserID,
        opponentDataUpdate
      );

      // Update your firestore document to reflect your loss
      await UpdateDocumentOnFirestore(
        userTableName,
        userDocRef.userID,
        userDataUpdate
      );
    }
  };

  const GenerateRoundLogs = (
    winLog: boolean,
    player1Move?: string,
    player1Damage?: number,
    player2Move?: string,
    player2Damage?: number
  ) => {
    const BlockAmount = (
      move: string,
      isPlayer1: boolean,
      extraDamage: number
    ) => {
      let blockAmount = 0;
      if (["jab", "uppercut", "block"].includes(move)) {
        blockAmount += basicMoves[move].value + extraDamage;
      } else {
        let specialMove;
        if (isPlayer1) {
          specialMove = currentBrawlRef.player1SpecialMove;
        } else {
          specialMove = currentBrawlRef.live
            ? userDocRef.specialMove
            : currentBrawlRef.player2SpecialMove;
        }

        blockAmount +=
          specialMoves[toCamelCase(specialMove) as keyof typeof specialMoves]
            .stats.enemyDamage + extraDamage;
      }

      if (blockAmount > 2) {
        blockAmount = 2;
      }

      return blockAmount;
    };

    const opponentUsername = currentBrawlRef.live
      ? currentBrawlRef.player1Username
      : currentBrawlRef.player2Username;
    const wager = currentBrawlRef.wagerAmount;
    const player1WeaponDamage = getStatValue(
      currentBrawlRef.player1WeaponRarity
    );
    const player2WeaponDamage = getStatValue(
      currentBrawlRef.live
        ? userDocRef.equippedItems.weapon.itemRarity
        : currentBrawlRef.player2WeaponRarity
    );
    const winByDefaultText = `${opponentUsername} has no health remaining. This brawl was your victory! A win has been added to your record and ${
      wager * 2
    } $Brawl has been awarded to you (this includes the wager you made, plus ${opponentUsername}'s wager).`;
    const loseByDefaultText = `You have no health remaining. This brawl was ${opponentUsername}'s victory! 
      A loss has been added to your record and you have lost the ${wager} $Brawl you put into the wager.`;
    let logText = "";

    if (winLog) {
      if (
        playerStatus.player1.health <= 0 &&
        playerStatus.player2.health <= 0
      ) {
        logText = `You and ${opponentUsername} have no health remaining. You have tied by default. A tie has been added to your record and the ${wager} $Brawl you wagered has been returned to you.`;
      } else if (playerStatus.player1.health <= 0) {
        logText = currentBrawlRef.live ? winByDefaultText : loseByDefaultText;
      } else if (playerStatus.player2.health <= 0) {
        logText = currentBrawlRef.live ? loseByDefaultText : winByDefaultText;
      } else if (playerWonRef.current === "loss") {
        logText = `This brawl was ${opponentUsername}'s victory! A loss has been added to your record and you have lost the ${wager} $Brawl you put into the wager.`;
      } else if (playerWonRef.current === "win") {
        logText = `This brawl was your victory! A win has been added to your record and ${
          wager * 2
        } $Brawl has been awarded to you (this includes the wager you made, plus ${opponentUsername}'s wager).`;
      } else {
        logText = `You and ${opponentUsername} have tied! A tie has been added to your record and the ${wager} $Brawl you wagered has been returned to you.`;
      }
    } else if (
      player1Move &&
      player1Damage !== undefined &&
      player2Move &&
      player2Damage !== undefined
    ) {
      // Calculates the amount each player has blocked if they used a block move
      const player1BlockAmount = BlockAmount(
        player1Move,
        true,
        player1WeaponDamage
      );
      const player2BlockAmount = BlockAmount(
        player2Move,
        false,
        player2WeaponDamage
      );

      // Checks if either player has used their specials
      const player1SpecialUsed = !["jab", "uppercut", "block", "???"].includes(
        player1Move
      );
      const player2SpecialUsed = !["jab", "uppercut", "block", "???"].includes(
        player2Move
      );

      // The moves of the user viewing the brawl
      const yourMove = currentBrawlRef.live ? player2Move : player1Move;
      const yourSpecialUsed = currentBrawlRef.live
        ? player2SpecialUsed
        : player1SpecialUsed;
      const yourDamage = currentBrawlRef.live ? player2Damage : player1Damage;
      const yourBlockAmount = currentBrawlRef.live
        ? player2BlockAmount
        : player1BlockAmount;
      // The moves of the user not connected to the brawl
      const opponentMove = currentBrawlRef.live ? player1Move : player2Move;
      const opponentSpecialUsed = currentBrawlRef.live
        ? player1SpecialUsed
        : player2SpecialUsed;
      const opponentDamage = currentBrawlRef.live
        ? player1Damage
        : player2Damage;
      const opponentBlockAmount = currentBrawlRef.live
        ? player1BlockAmount
        : player2BlockAmount;

      logText = `You used a ${yourMove} and ${opponentUsername} used a ${opponentMove}.`;

      if (player1SpecialUsed) {
        let player1SpecialMove = currentBrawlRef.player1SpecialMove;

        logText += specialMoves[
          toCamelCase(player1SpecialMove) as SpecialMoveKeys
        ].logFunction(false, opponentUsername);
      }

      if (player2SpecialUsed) {
        let player2SpecialMove = currentBrawlRef.live
          ? userDocRef.specialMove
          : currentBrawlRef.player2SpecialMove;

        logText += specialMoves[
          toCamelCase(player2SpecialMove) as SpecialMoveKeys
        ].logFunction(false, opponentUsername);
      }

      if (playerStatus.player1.debuffs.name !== "null") {
        const isOpponent = currentBrawlRef.live ? true : false;
        logText += debuffs[
          playerStatus.player1.debuffs.name as keyof typeof debuffs
        ].logFunction(isOpponent, opponentUsername);
      }

      if (playerStatus.player1.buffs.name !== "null") {
        const isOpponent = currentBrawlRef.live ? true : false;
        logText += buffs[
          playerStatus.player1.buffs.name as keyof typeof buffs
        ].logFunction(isOpponent, opponentUsername);
      }

      if (playerStatus.player2.debuffs.name !== "null") {
        const isOpponent = currentBrawlRef.live ? false : true;
        logText += debuffs[
          playerStatus.player2.debuffs.name as keyof typeof debuffs
        ].logFunction(isOpponent, opponentUsername);
      }

      if (playerStatus.player2.buffs.name !== "null") {
        const isOpponent = currentBrawlRef.live ? false : true;
        logText += buffs[
          playerStatus.player2.buffs.name as keyof typeof buffs
        ].logFunction(isOpponent, opponentUsername);
      }

      if (yourMove === "block" && opponentMove === "block") {
        logText += ` No damage was done or taken!`;
      } else if (
        (yourMove === "jab" && opponentMove === "uppercut") ||
        (yourMove === "uppercut" && opponentMove === "jab") ||
        yourMove === opponentMove ||
        (yourSpecialUsed && opponentSpecialUsed) ||
        (yourSpecialUsed && opponentMove === "jab") ||
        (yourSpecialUsed && opponentMove === "uppercut") ||
        (opponentSpecialUsed && yourMove === "jab") ||
        (opponentSpecialUsed && yourMove === "uppercut")
      ) {
        logText += ` You did ${yourDamage} damage and took ${opponentDamage} damage!`;
      } else if (opponentMove === "block") {
        logText += ` They blocked ${yourBlockAmount} damage and took ${yourDamage} damage!`;
      } else if (yourMove === "block") {
        logText += ` You blocked ${opponentBlockAmount} damage and took ${opponentDamage} damage!`;
      }
    }

    return logText;
  };

  const GovernRound = (round: number) => {
    // if you start a brawl, you are player 1
    const player1Move = currentBrawlRef.player1Moves[round - 1];
    // if you accept a brawl, you are player 2
    const player2Move = currentBrawlRef.live
      ? selectedMovesRef.current[round - 1]
      : currentBrawlRef.player2Moves[round - 1];

    // Calculates the damage done by both players, including by special moves, debuffs and buffs
    const damageValues = CalculateDamageAndEffects(player1Move, player2Move);

    // Set damage taken values
    damageTaken.player1 += damageValues.player2Damage;
    damageTaken.player2 += damageValues.player1Damage;
    setDamageTaken(damageTaken);

    // Decrement each players health
    playerStatus.player1.health -= damageValues.player2Damage;
    playerStatus.player2.health -= damageValues.player1Damage;
    setPlayerStatus(playerStatus);

    // Creates a new round log
    const roundLog: string = GenerateRoundLogs(
      false,
      player1Move,
      damageValues.player1Damage,
      player2Move,
      damageValues.player2Damage
    );

    // Sets the round logs
    setRoundLogs((prevLogs: any) => [{ log: roundLog }, ...prevLogs]);

    if (playerStatus.player1.health <= 0 || playerStatus.player2.health <= 0) {
      setTimeout(() => {
        // Generates a new round log
        const message: string = GenerateRoundLogs(true);
        setOverviewMessage(message);

        setShowOverviewPopup(true);
      }, (brawlTime / 7) * 1000);
    }
  };

  const ProgressBrawl = async () => {
    // Show the Versus screen and start its logic
    setShowVSScreen(true);
    StartVSScreenLogic(vsTime, vsTimeAnimationOffset);

    // If the brawl is live
    if (currentBrawlRef.live) {
      // Prime move select screen to be visible before versus screen ends
      setTimeout(() => {
        setShowMoveSelectScreen(true);
        setRoundTimer(movesTime);
      }, (vsTime + vsTimeAnimationOffset) * 1000);

      // Start the move select screen logic
      setTimeout(() => {
        setShowVSScreen(false);
        StartMoveScreenLogic(movesTime, movesTimeAnimationOffset);
      }, (vsTime + vsTimeAnimationOffset + 1) * 1000);

      // pick winner and update user data then show the brawling screen and start its logic for the playout
      if (!forfeiting) {
        setTimeout(async () => {
          // close forfeit popup
          setShowForfeitPopup(false);

          // Pick jabs for any unpicked moves
          AutoPickJabs();

          // pick winner and update user data
          await PickWinner(false);

          // set the move select screen to hidden and the brawling screen to visible
          setShowMoveSelectScreen(false);
          setShowBrawlingScreen(true);

          // Start the brawling screen logic
          StartBrawlingScreenLogic(brawlTime, brawlTimeAnimationOffset);
        }, (vsTime + movesTime + vsTimeAnimationOffset + movesTimeAnimationOffset + 1) * 1000);
      }
    } else {
      // Prime brawling screen to be visible before versus screen ends
      if (!currentBrawlRef.forfeit) {
        setTimeout(() => {
          setShowBrawlingScreen(true);
        }, vsTime * 1000);
      }

      // Start the brawling screen logic
      setTimeout(async () => {
        if (!currentBrawlRef.forfeit) {
          // pick winner and update user data
          await PickWinner(false);
          setShowVSScreen(false);
          StartBrawlingScreenLogic(brawlTime, brawlTimeAnimationOffset);
        } else {
          setShowVSScreen(false);
          setShowForfeitPopup(true);
        }
      }, (vsTime + vsTimeAnimationOffset + 1) * 1000);
    }
  };

  return (
    <div className="brawl-visuals-page-body">
      {!showVSScreen ? (
        <div className="brawl-visuals-top-bar">
          {showBrawlingScreen ? (
            <div>
              <button
                onClick={() => {
                  SkipBrawl();
                }}
                className="brawl-visual-top-btn"
              >
                Skip
              </button>
              <div className="brawl-visuals-top-txt-container">
                <div className="brawl-visuals-top-txt">
                  {currentBrawlRef.wagerAmount} $Brawl
                </div>
                <div className="brawl-visuals-top-countdown-txt">
                  {roundTimer} seconds
                </div>
              </div>
            </div>
          ) : (
            <div>
              <button
                onClick={() => {
                  setShowForfeitPopup(true);
                }}
                className="brawl-visual-top-btn"
              >
                Forfeit Brawl
              </button>
              <div className="brawl-visuals-top-txt-container">
                <div className="brawl-visuals-top-txt">Pick your moves</div>
                <div className="brawl-visuals-top-countdown-txt">
                  All unpicked moves automatically become jabs
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}

      {showVSScreen ? <BrawlVSScreen roundTimer={roundTimer} /> : null}
      {showMoveSelectScreen ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <MoveSelectScreen
            selectedMoves={selectedMoves}
            setSelectedMoves={setSelectedMoves}
            roundTimer={roundTimer}
          />
        </div>
      ) : null}
      {showBrawlingScreen ? (
        <BrawlingScreen
          damageTaken={damageTaken}
          roundLogs={roundLogs}
          playerStatus={playerStatus}
          player1Move={currentBrawlRef.player1Moves[roundLogs.length - 1]}
          player2Move={
            currentBrawlRef.live
              ? selectedMoves[roundLogs.length - 1]
              : currentBrawlRef.player2Moves[roundLogs.length - 1]
          }
        />
      ) : null}
      <TransitionScreen
        transitionActive={transitionActive}
        startOnCreation={true}
      />
      <BrawlOverviewPopup
        userWon={playerWon}
        overviewText={overviewMessage}
        earnedItemData={earnedItemData}
        showOverviewPopup={showOverviewPopup}
      />
      <ForfeitPopup
        showForfeitPopup={showForfeitPopup}
        setShowForfeitPopup={setShowForfeitPopup}
        roundTimer={roundTimer}
        ForfeitBrawl={ForfeitBrawl}
        forfeiting={forfeiting}
        earnedItemData={earnedItemData}
        userWon={playerWon}
      />
    </div>
  );
}
