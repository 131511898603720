// CSS
import "./move-select-button.css";

interface MoveSelectButtonProps {
  UpdateMoves: any;
  currentRound: number;
  disabled: boolean;
  move: string;
  moveImage: string;
}

export default function MoveSelectButton({
  UpdateMoves,
  currentRound,
  disabled,
  move,
  moveImage,
}: MoveSelectButtonProps) {
  return (
    <button
      onClick={() => {
        UpdateMoves(move.toLowerCase(), currentRound);
      }}
      disabled={disabled}
      className="move-select-div"
    >
      <img src={moveImage} className="move-select-img" draggable={false}></img>
      <div
        className="move-select-btn"
        style={
          disabled
            ? {
                backgroundColor: "#888888",
              }
            : { backgroundImage: "linear-gradient(to right, #0297fc, #1184d0)" }
        }
      >
        {move}
      </div>
    </button>
  );
}
