// CSS
import "./location-box.css";

interface LocationBoxProps {
  name: string;
  controlledGraphic: boolean;
  graphicSetter: any;
  positioning: any;
  CloseAll: any;
}

export default function LocationBox({
  name,
  controlledGraphic,
  graphicSetter,
  positioning,
  CloseAll,
}: LocationBoxProps) {
  return (
    <button
      onClick={() => {
        CloseAll();
        graphicSetter(!controlledGraphic);
      }}
      className="location-box"
      style={positioning}
    >
      <h6 className="location-text">{name}</h6>
    </button>
  );
}
