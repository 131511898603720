// CSS
import "./leaderboard-item.css";

interface LeaderboardItemProps {
  userData: any;
  type: string;
}

export default function LeaderboardItem({
  userData,
  type,
}: LeaderboardItemProps) {
  return (
    <div className="leaderboard-item-div">
      <div className="leaderboard-img-container">
        <h1 className="leaderboard-item-rank-txt">{userData.rank}</h1>
        <img src={userData.profilePic} className="leaderboard-item-img"></img>
      </div>
      <div className="leaderboard-item-details-container">
        <h4 className="leaderboard-name-txt">{userData.username}</h4>
        <h6 className="leaderboard-reward-txt">{userData.twitter}</h6>
      </div>
      <div className="leaderboard-item-brawl-txt">
        {type === "brawl" ? `${userData.$Brawl}` : userData.wins}
      </div>
    </div>
  );
}
