// React Components
import MarketplaceItem from "./MarketplaceItem";

// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import { specialMoves } from "../../Constants";

// CSS
import "./marketplace-mapover.css";

export default function MarketplaceMapover() {
  const { userDocRef, setShowMarketplaceMapover } = useSettings();

  const GenerateShopItems = () => {
    const items = Object.values(specialMoves).map(
      (moveData: any, index: number) => {
        if (moveData.name === userDocRef.specialMove.name) {
          return null; // or return something else if you need to
        } else {
          return (
            <MarketplaceItem key={index} moveData={moveData} equipped={false} />
          );
        }
      }
    );

    const groupedItems = [];
    for (let i = 0; i < items.length; i += 2) {
      groupedItems.push(
        <div key={i / 2} style={{ display: "flex" }}>
          <div style={{ marginRight: "0.5rem", width: "100%" }}>{items[i]}</div>
          {items[i + 1] || null}
        </div>
      );
    }

    return <>{groupedItems}</>;
  };

  const GenerateEquippedItem = () => {
    let item = null;

    for (const move of Object.values(specialMoves)) {
      if (move.name === userDocRef.specialMove) {
        item = <MarketplaceItem moveData={move} equipped={true} />;
        break;
      }
    }

    if (!item) {
      item = <MarketplaceItem moveData={{}} equipped={true} />;
    }

    return item;
  };

  return (
    <div className={`marketplace-mapover-backdrop`}>
      <div className={`marketplace-mapover-div`}>
        <h1 className="marketplace-mapover-title-txt">The Brawl Shop</h1>
        <p className="marketplace-mapover-description-txt">
          For all your special move needs. Any moves purchased will replace your
          currently equipped move. No refunds.
        </p>
        <div>
          <h3 className="marketplace-mapover-equipped-special-txt">
            Current Special:
          </h3>
          {GenerateEquippedItem()}
        </div>
        <div className="marketplace-mapover-item-container">
          {GenerateShopItems()}
        </div>
        <button
          onClick={() => {
            setShowMarketplaceMapover(false);
          }}
          className="marketplace-mapover-close-btn"
        >
          <b>X</b>
        </button>
      </div>
    </div>
  );
}
