// Constants
import { itemRarities } from "../../../Constants";

// Global Functions
import {
  getRarityColor,
  getStatValue,
  capitalizeFirstLetter,
} from "../../../globalFunctions";

// Settings Context
import { useSettings } from "../../../settingsContext";

// CSS
import "./forfeit-popup.css";

interface ForfeitPopupProps {
  showForfeitPopup: boolean;
  setShowForfeitPopup: any;
  roundTimer: number;
  ForfeitBrawl: any;
  forfeiting: boolean;
  earnedItemData: any;
  userWon: string;
}

export default function ForfeitPopup({
  showForfeitPopup,
  setShowForfeitPopup,
  roundTimer,
  ForfeitBrawl,
  forfeiting,
  earnedItemData,
  userWon,
}: ForfeitPopupProps) {
  const { currentBrawlRef } = useSettings();

  const forfeitMessage = `By forfeiting, you will lose this brawl. 
  You will take the full damage of your opponent's moves and will lose your wager. 
  You have ${roundTimer} seconds before the point of no return. 
  Are you sure you want to forfeit this brawl?`;

  const opponentForfeitedMessage = `${currentBrawlRef.player2Username} forfeited before the match could start! 
  ${currentBrawlRef.player2Username} has taken the full damage of your moves and you have taken no damage. 
  You win by default.`;

  return (
    <div
      className={`forfeit-popup-backdrop ${
        showForfeitPopup ? "open" : "close"
      }`}
    >
      <div
        className={`forfeit-popup-body ${showForfeitPopup ? "open" : "close"}`}
      >
        <div>
          <div className="forfeit-popup-heading">
            {currentBrawlRef.live ? "Forfeit Brawl?" : "Opponent Forfeited!"}
          </div>
          <div className="forfeit-popup-txt">
            {currentBrawlRef.live ? forfeitMessage : opponentForfeitedMessage}
          </div>
          {earnedItemData.type !== "null" && currentBrawlRef.forfeit ? (
            <div>
              <div className="brawl-popup-overview-earned-txt">You earned:</div>
              <div className="brawl-overview-popup-popup-container">
                <div className="brawl-overview-popup-row">
                  <div
                    className="brawl-overview-popup-img-container"
                    style={{
                      backgroundColor: getRarityColor(earnedItemData.rarity),
                    }}
                  >
                    <img src=""></img>
                    <div
                      className="brawl-overview-popup-stat-txt"
                      style={
                        earnedItemData.type === "weapon"
                          ? { backgroundColor: "#0297fc" }
                          : { backgroundColor: "#2ab855" }
                      }
                    >
                      +{getStatValue(earnedItemData.rarity)}{" "}
                      {earnedItemData.type === "weapon" ? " damage" : " health"}
                    </div>
                  </div>
                  <div>
                    <div className="brawl-overview-popup-type-txt">
                      {capitalizeFirstLetter(earnedItemData.type)}
                    </div>
                    <div className="brawl-overview-popup-collection-txt">
                      {capitalizeFirstLetter(earnedItemData.collection)}
                    </div>
                    <div className="brawl-overview-popup-rarity-txt">
                      {capitalizeFirstLetter(earnedItemData.rarity)}
                    </div>
                  </div>
                </div>
                <div className="brawl-overview-popup-equip-txt">
                  {earnedItemData.dupe
                    ? `This item is a duplicate of one you already own. For its rarity, you have been awarded ${
                        itemRarities[
                          earnedItemData.rarity as keyof typeof itemRarities
                        ].duplicateReward
                      } $Brawl.`
                    : earnedItemData.replace
                    ? "This item has a higher rarity than the item currently being used and has therefore been equipped."
                    : "This item is inferior or on par with the item you are currently using. No changes have been made."}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {currentBrawlRef.live ? (
          <div>
            <button
              onClick={async () => {
                await ForfeitBrawl();
              }}
              disabled={forfeiting}
              className="forfeit-popup-btn"
            >
              Forfeit
            </button>
            <button
              onClick={() => {
                setShowForfeitPopup(false);
              }}
              disabled={forfeiting}
              className="forfeit-popup-btn"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={() => {
                setShowForfeitPopup(false);
              }}
              className="forfeit-popup-btn"
            >
              Return
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
