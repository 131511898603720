// React
import { useEffect, useState } from "react";

// React Components
import BankItem from "../Items/BankItem";
import RarityItem from "../Items/RarityItem";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Constants
import { itemPool, itemRarities } from "../../../Constants";

// CSS
import "./bank-contents.css";

interface BankContentsProps {
  equippedItems: any;
}

export default function BankContents({ equippedItems }: BankContentsProps) {
  const { userDocRef } = useSettings();

  const [activeTab, setActiveTab] = useState("equipped");
  const [activeCollection, setActiveCollection] = useState("none");
  const [rarities, setRarities] = useState<string[]>([]);

  useEffect(() => {
    let invertedRarities: string[] = [];

    for (const key in itemRarities) {
      if (itemRarities[key as keyof typeof itemRarities].name !== "default") {
        invertedRarities.push(
          itemRarities[key as keyof typeof itemRarities].name
        );
      }
    }

    setRarities(invertedRarities.slice().reverse());
  }, []);

  const GetRarityOwnedStatus = (rarity: string) => {
    return userDocRef.inventory[activeTab] &&
      userDocRef.inventory[activeTab][activeCollection.toLowerCase()]
      ? userDocRef.inventory[activeTab][activeCollection.toLowerCase()][
          rarity.toLowerCase()
        ]
      : "";
  };

  const ChunkArray = (array: any, chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const GenerateTabContents = () => {
    if (activeTab === "equipped") {
      const itemRows = ChunkArray(equippedItems, 3);
      return itemRows.map((row, rowIndex) => (
        <div key={rowIndex} className="bank-contents-row">
          {row.map(({ slotName, data }: any) => (
            <BankItem
              key={slotName}
              slotName={slotName}
              itemIcon=""
              itemRarity={data.itemRarity}
              itemCollection={data.collection}
              setActiveTab={setActiveTab}
            />
          ))}
        </div>
      ));
    } else if (activeTab !== "equipped" && activeCollection === "none") {
      const itemRows = ChunkArray(
        itemPool[activeTab as keyof typeof itemPool],
        3
      );
      return itemRows.map((row: any, rowIndex: number) => (
        <div key={rowIndex} className="bank-contents-row">
          {row.map(({ collectionName, index }: any) => (
            <BankItem
              key={index}
              slotName={activeTab}
              itemIcon=""
              itemCollection={collectionName}
              setActiveCollection={setActiveCollection}
            />
          ))}
        </div>
      ));
    } else if (activeCollection !== "none") {
      const itemRows = ChunkArray(rarities, 3);
      return itemRows.map((row: any, rowIndex: number) => (
        <div key={rowIndex} className="bank-contents-row">
          {row.map((rarity: string, index: number) => (
            <RarityItem
              key={index}
              itemName={activeTab}
              itemCollection={activeCollection}
              itemIcon=""
              itemRarity={rarity}
              owned={GetRarityOwnedStatus(rarity)}
            />
          ))}
        </div>
      ));
    }
  };

  return (
    <div className="bank-contents-view-container">
      <div className="bank-contents-title-container">
        <h4 className="bank-contents-title-txt">
          {activeTab === "equipped"
            ? "Equipped Items"
            : activeTab !== "equipped" && activeCollection === "none"
            ? `${activeTab}`
            : activeCollection !== "none"
            ? userDocRef.inventory[activeTab] &&
              userDocRef.inventory[activeTab][activeCollection.toLowerCase()]
              ? activeCollection
              : "???"
            : "Equipped Items"}
        </h4>
      </div>
      <div className="bank-contents-items-container">
        {GenerateTabContents()}
      </div>
      {activeTab !== "equipped" ? (
        <button
          onClick={() => {
            activeCollection === "none"
              ? setActiveTab("equipped")
              : setActiveCollection("none");
          }}
          className="bank-contents-back-btn"
        >
          Back
        </button>
      ) : null}
    </div>
  );
}
