// React
import { useEffect, useState } from "react";

// React Components
import Navbar from "../components/Navbar/Navbar";
import LocationBox from "../components/Home/LocationBox/LocationBox";
import BrawlPopout from "../components/BrawlPopout/brawlPopout";
import QuestsPopout from "../components/QuestsPopout/QuestsPopout";
import HospitalPopout from "../components/HospitalPopout/HospitalPopout";
import MarketplaceMapover from "../components/MarketplaceMapover/MarketplaceMapover";
import AccountMapover from "../components/AccountMapover/AccountMapover";
import BankPopout from "../components/BankPopout/BankPopout";
import TransitionScreen from "../components/TransitionScreen/TransitionScreen";
import LeaderboardPopout from "../components/QuestsPopout copy/LeaderboardPopout";

// Constants
import { itemRarities, userTableName } from "../Constants";

// Settings Context
import { useSettings } from "../settingsContext";

// Firebase
import {
  OpenBrawlsSnapshot,
  UpdateDocumentOnFirestore,
  UserDataSnapshot,
} from "../firebaseconfig";

// CSS
import "./homepage.css";

export default function HomePage() {
  const {
    userDocRef,
    showQuestsPopout,
    showBrawlPopout,
    showHospitalPopout,
    showBankPopout,
    showLeaderboardPopout,
    showMarketplaceMapover,
    showAccountMapover,
    transitionActive,
    setUserDocRef,
    setOpenBrawls,
    setShowQuestsPopout,
    setShowBrawlPopout,
    setShowHospitalPopout,
    setShowBankPopout,
    setShowLeaderboardPopout,
    setShowMarketplaceMapover,
  } = useSettings();

  useEffect(() => {
    const fetchData = async () => {
      // Triggers a snapshot so any changes in the user's database profile are reflected immediately on the web app
      await UserDataSnapshot(userDocRef.userID, setUserDocRef);

      // Triggers a snapshot so any new additions or removals in the open brawls database table are reflected immediately on the web app
      await OpenBrawlsSnapshot(setOpenBrawls);

      // Gets all the health-related items currently equipped by the user and
      // adds them together with a base health value to give the user's item boosted max health
      const { helmet, chest, gloves, pants, boots } = userDocRef.equippedItems;
      const maxHealthCheck =
        100 +
        itemRarities[helmet.itemRarity as keyof typeof itemRarities].statValue +
        itemRarities[chest.itemRarity as keyof typeof itemRarities].statValue +
        itemRarities[gloves.itemRarity as keyof typeof itemRarities].statValue +
        itemRarities[pants.itemRarity as keyof typeof itemRarities].statValue +
        itemRarities[boots.itemRarity as keyof typeof itemRarities].statValue;

      // Conducts a check to see if the max health in the database is the same as what their items should boost it by
      if (
        userDocRef.maxHealth < maxHealthCheck ||
        userDocRef.maxHealth > maxHealthCheck
      ) {
        if (userDocRef.health > maxHealthCheck) {
          // If the user's max health is not correct, it will update their database profile with the correct value
          await UpdateDocumentOnFirestore(userTableName, userDocRef.userID, {
            maxHealth: maxHealthCheck,
            health: maxHealthCheck,
          });
        } else {
          // If the user's max health is not correct, it will update their database profile with the correct value
          await UpdateDocumentOnFirestore(userTableName, userDocRef.userID, {
            maxHealth: maxHealthCheck,
          });
        }
      }
    };

    fetchData();
  }, []);

  const CloseAll = () => {
    setShowBrawlPopout(false);
    setShowQuestsPopout(false);
    setShowHospitalPopout(false);
    setShowBankPopout(false);
    setShowLeaderboardPopout(false);
  };

  const [numbers, setNumbers] = useState<any>([]);

  return (
    <div className="homepage-div vignette">
      {/* <img
        src="https://i.imgur.com/ePp2LuN.jpeg"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      ></img> */}
      <Navbar />

      <button
        onClick={() => {
          CloseAll();
        }}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          border: "0px solid black",
          backgroundColor: "transparent",
          zIndex: 1,
        }}
      ></button>
      <h1
        style={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          fontFamily: "Staatliches",
          margin: "2rem 0 0 0",
        }}
      >
        Brawl City
      </h1>
      <div>
        <LocationBox
          name="Brawl"
          controlledGraphic={showBrawlPopout}
          graphicSetter={setShowBrawlPopout}
          positioning={{ top: "50%", left: "50%" }}
          CloseAll={CloseAll}
        />
        <LocationBox
          name="Marketplace"
          controlledGraphic={showMarketplaceMapover}
          graphicSetter={setShowMarketplaceMapover}
          positioning={{ top: "50%", left: "25%" }}
          CloseAll={CloseAll}
        />
        <LocationBox
          name="Hospital"
          controlledGraphic={showHospitalPopout}
          graphicSetter={setShowHospitalPopout}
          positioning={{ top: "75%", left: "33%" }}
          CloseAll={CloseAll}
        />
        <LocationBox
          name="Tavern"
          controlledGraphic={showQuestsPopout}
          graphicSetter={setShowQuestsPopout}
          positioning={{ top: "75%", left: "66%" }}
          CloseAll={CloseAll}
        />
        <LocationBox
          name="Bank"
          controlledGraphic={showBankPopout}
          graphicSetter={setShowBankPopout}
          positioning={{ top: "25%", left: "50%" }}
          CloseAll={CloseAll}
        />
        <LocationBox
          name="Billboard"
          controlledGraphic={showLeaderboardPopout}
          graphicSetter={setShowLeaderboardPopout}
          positioning={{ top: "50%", left: "75%" }}
          CloseAll={CloseAll}
        />
      </div>
      <BrawlPopout />
      <QuestsPopout />
      <HospitalPopout />
      <BankPopout />
      <LeaderboardPopout />
      {showMarketplaceMapover ? <MarketplaceMapover /> : null}
      {showAccountMapover ? <AccountMapover /> : null}
      <TransitionScreen
        transitionActive={transitionActive}
        startOnCreation={true}
      />
    </div>
  );
}
