// Settings Context
import { useSettings } from "../../../settingsContext";

// Constants
import { unsetProfilePic, userTableName } from "../../../Constants";

// Firestore
import { increment } from "firebase/firestore";
import { UpdateDocumentOnFirestore } from "../../../firebaseconfig";

// CSS
import "./quest-item.css";

interface QuestItemProps {
  questData: any;
}

export default function QuestItem({ questData }: QuestItemProps) {
  const { userDocRef } = useSettings();

  const ClaimQuest = async () => {
    if (questData.progress >= questData.requirement) {
      const questType = questData.questType;
      const questIndex = questData.questIndex;
      const reward = questData.reward;

      userDocRef.questLines[questType][questIndex].claimed = true;
      const questLineUpdate = {
        [`questLines.${questType}`]: userDocRef.questLines[questType],
      };

      const update = {
        $Brawl: increment(reward),
        ...questLineUpdate,
      };

      await UpdateDocumentOnFirestore(userTableName, userDocRef.userID, update);
    }
  };

  return (
    <div className="quest-item-div">
      <div className="quest-img-container">
        <img src={unsetProfilePic} className="quest-item-img"></img>
        <div
          className="quest-progress-container"
          style={
            questData.progress === questData.requirement
              ? { backgroundColor: "#0297fc" }
              : { backgroundColor: "#ff4444" }
          }
        >
          <h6 className="quest-progress-txt">
            {questData.requirement === 0
              ? "Done"
              : `${questData.progress}/${questData.requirement}`}
          </h6>
        </div>
      </div>

      <div className="quest-item-details-container">
        <h4 className="quest-name-txt">{questData.title}</h4>
        <h6 className="quest-reward-txt">
          {questData.requirement === 0 ? null : `+${questData.reward} $Brawl`}
        </h6>
      </div>
      {questData.requirement === 0 ? null : (
        <button
          onClick={async () => {
            await ClaimQuest();
          }}
          disabled={questData.progress === questData.requirement ? false : true}
          className="quest-item-claim-btn"
          style={
            questData.progress === questData.requirement
              ? {
                  backgroundImage:
                    "linear-gradient(to right, #0297fc, #1184d0)",
                }
              : { backgroundColor: "#888888" }
          }
        >
          Claim
        </button>
      )}
    </div>
  );
}
