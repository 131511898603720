// Initialisation
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Firebase Auth
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

// Firebase Firestore
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";

// Constants
import { brawlListingsTableName, userTableName } from "./Constants";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhUXZ46pIQiNBg25WmEPajgIu0e2kzljA",
  authDomain: "brawlclub-bullies.firebaseapp.com",
  projectId: "brawlclub-bullies",
  storageBucket: "brawlclub-bullies.appspot.com",
  messagingSenderId: "647422753924",
  appId: "1:647422753924:web:a5e1125c4d90018070d6d1",
  measurementId: "G-QE2KMV7L5F",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// FIRESTORE DATABASE
const db = getFirestore();

export const SaveDataToFirestore = async (
  dbCollection: string,
  docId: string,
  data: any
) => {
  let docRef;
  if (docId === "") {
    docRef = doc(collection(db, dbCollection));
  } else {
    docRef = doc(collection(db, dbCollection), docId);
  }

  await setDoc(docRef, data);

  return data;
};

export const GetDataFromFirestore = async (
  dbCollection: string,
  docId: string
) => {
  const userDocRef = doc(collection(db, dbCollection), docId);

  const docSnap = await getDoc(userDocRef);

  return docSnap.data();
};

export const UserDataSnapshot = async (docId: string, setUserDocRef: any) => {
  const unsub = onSnapshot(doc(db, userTableName, docId), (doc) => {
    setUserDocRef(doc.data());
  });
};

export const OpenBrawlsSnapshot = async (setOpenBrawls: any) => {
  const unsub = onSnapshot(
    collection(db, brawlListingsTableName),
    (snapshot) => {
      setOpenBrawls(snapshot.docs);
    }
  );
};

export const GetBatchDataFromFirestore = async (dbCollection: string) => {
  const docSnap = await getDocs(collection(db, dbCollection));
  const documentRefs: any = [];
  docSnap.forEach((doc) => {
    documentRefs.push(doc);
  });
  return documentRefs;
};

export const GetDataForLeaderboard = async (type: string) => {
  const usersRef = collection(db, userTableName);
  let leaderboard: any[] = [];
  let ranking: number = 1;

  if (type === "brawl") {
    const q = query(usersRef, orderBy("$Brawl", "desc"));
    const docSnap = await getDocs(q);

    docSnap.forEach((doc) => {
      leaderboard.push({ id: doc.id, rank: ranking, ...doc.data() });
      ranking++;
    });
  } else {
    const q = query(usersRef, orderBy("wins", "desc"));
    const docSnap = await getDocs(q);

    docSnap.forEach((doc) => {
      leaderboard.push({ id: doc.id, rank: ranking, ...doc.data() });
      ranking++;
    });
  }

  return leaderboard;
};

export const UpdateDocumentOnFirestore = async (
  collectionName: string,
  docId: string,
  newData: Record<string, any>
) => {
  try {
    await updateDoc(doc(db, collectionName, docId), newData);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const DeleteDocumentFromFirestore = async (
  collectionName: string,
  docId: string
) => {
  await deleteDoc(doc(db, collectionName, docId));
};

// USER AUTHENTICATION
const auth = getAuth();

export const CreateNewUser = async (
  email: string,
  password: string,
  username: string,
  twitter: string,
  bio: string,
  profilePic: any
) => {
  let result: { [key: string]: any } = {};
  await createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed up
      const user = userCredential.user;
      const userData = await SaveDataToFirestore(userTableName, user.uid, {
        // Profile
        userID: user.uid,
        username: username,
        twitter: twitter,
        bio: bio,
        profilePic: profilePic,

        // Stats
        $Brawl: 10000,
        wins: 0,
        losses: 0,
        ties: 0,

        // Status
        health: 100,
        maxHealth: 100,
        specialMove: "",

        // Progression data
        brawlHistory: [],
        questLines: {
          startBrawls: [
            {
              progress: 0,
              claimed: false,
            },
            {
              progress: 0,
              claimed: false,
            },
            {
              progress: 0,
              claimed: false,
            },
          ],
          winBrawls: [
            {
              progress: 0,
              claimed: false,
            },
            {
              progress: 0,
              claimed: false,
            },
            {
              progress: 0,
              claimed: false,
            },
          ],
          playBrawls: [
            {
              progress: 0,
              claimed: false,
            },
            {
              progress: 0,
              claimed: false,
            },
            {
              progress: 0,
              claimed: false,
            },
          ],
        },
        weeklyQuests: {
          kingOfTheRing: {
            progress: 0,
            claimed: false,
          },
          kingOfTheHill: {
            progress: 0,
            claimed: false,
          },
        },

        // Inventory related
        equippedItems: {
          weapon: {
            itemName: "nothing",
            itemRarity: "default",
            collection: "default",
          },
          helmet: {
            itemName: "nothing",
            itemRarity: "default",
            collection: "default",
          },
          chest: {
            itemName: "nothing",
            itemRarity: "default",
            collection: "default",
          },
          gloves: {
            itemName: "nothing",
            itemRarity: "default",
            collection: "default",
          },
          pants: {
            itemName: "nothing",
            itemRarity: "default",
            collection: "default",
          },
          boots: {
            itemName: "nothing",
            itemRarity: "default",
            collection: "default",
          },
        },
        inventory: {
          weapon: {},
          helmet: {},
          chest: {},
          gloves: {},
          pants: {},
          boots: {},
        },
      });
      result["success"] = true;
      result["docRef"] = userData;
    })
    .catch((error) => {
      // Failed to sign up
      const errorCode = error.code;
      const errorMessage = error.message;
      result["success"] = true;
      result["docRef"] = null;
    });
  return result;
};

export const SignInUser = async (email: string, password: string) => {
  let result: { [key: string]: any } = {};
  await signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      const userData = await GetDataFromFirestore(userTableName, user.uid);
      result["success"] = true;
      result["docRef"] = userData;
      // ...
    })
    .catch((error) => {
      console.log(error.message);

      result["success"] = false;
      result["docRef"] = null;
    });

  return result;
};

export const ResetUserPassword = async (email: string) => {
  await sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      // ..
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
};

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    console.log("User Signed In");
    // ...
  } else {
    // User is signed out
    // ...
  }
});
