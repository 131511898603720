// React
import { useEffect, useState } from "react";

// Settings Context
import { useSettings } from "../../../../settingsContext";

// CSS
import "./brawl-vs-screen.css";

interface BrawlVSScreenProps {
  roundTimer: number;
}

export default function BrawlVSScreen({ roundTimer }: BrawlVSScreenProps) {
  const { currentBrawlRef, userDocRef } = useSettings();

  const [showPlayers, setShowPlayers] = useState(false);
  const [showVersusText, setShowVersusText] = useState(false);
  const [showBrawlText, setShowBrawlText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPlayers(true);
    }, 1500);

    setTimeout(() => {
      setShowVersusText(true);
    }, 2000);

    setTimeout(() => {
      setShowBrawlText(true);
    }, 12000);

    setTimeout(() => {
      setShowPlayers(false);
      setShowVersusText(false);
    }, 13000);
  }, []);

  return (
    <div className="brawl-vs-screen-body">
      <div
        className={`brawl-vs-screen-vs-centerpiece ${
          showVersusText ? "open" : "close"
        }`}
      >
        {showBrawlText ? (
          <div>
            <h1 className="brawl-vs-screen-round-vs-txt">BRAWL!</h1>
          </div>
        ) : (
          <div>
            <h1 className="brawl-vs-screen-round-vs-txt">VERSUS</h1>
            <h1 className="brawl-vs-screen-round-time-txt">{roundTimer}</h1>
          </div>
        )}
      </div>
      <div
        className={`brawl-vs-screen-left-player-container ${
          showPlayers ? "open" : "close"
        }`}
      >
        <div className="brawl-vs-screen-player-wrapper">
          <img
            src={userDocRef.profilePic}
            className="brawl-vs-screen-player-img"
          ></img>
          <h1 className="brawl-vs-screen-player-name-txt">
            {userDocRef.username}
          </h1>
          <h1 className="brawl-vs-screen-player-stats-txt">
            {userDocRef.wins} Wins, {userDocRef.losses} Losses
          </h1>
        </div>
      </div>
      <div
        className={`brawl-vs-screen-right-player-container ${
          showPlayers ? "open" : "close"
        }`}
      >
        <div className="brawl-vs-screen-player-wrapper">
          <img
            src={
              currentBrawlRef.live
                ? currentBrawlRef.player1ProfilePic
                : currentBrawlRef.player2ProfilePic
            }
            className="brawl-vs-screen-player-img"
          ></img>
          <h1 className="brawl-vs-screen-player-name-txt">
            {currentBrawlRef.live
              ? currentBrawlRef.player1Username
              : currentBrawlRef.player2Username}
          </h1>
          <h1 className="brawl-vs-screen-player-stats-txt">
            {currentBrawlRef.live
              ? currentBrawlRef.player1Wins
              : currentBrawlRef.player2Wins}{" "}
            Wins,{" "}
            {currentBrawlRef.live
              ? currentBrawlRef.player1Losses
              : currentBrawlRef.player2Losses}{" "}
            Losses
          </h1>
        </div>
      </div>
    </div>
  );
}
