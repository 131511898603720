// Item pool for players to draw rewards from
type ItemPoolType = {
  collectionName: string;
};

export const itemPool: { [key: string]: ItemPoolType[] } = {
  weapon: [
    { collectionName: "swords" },
    { collectionName: "axes" },
    { collectionName: "maces" },
  ],
  helmet: [
    { collectionName: "great Helm" },
    { collectionName: "motorcycle" },
    { collectionName: "barbute" },
  ],
  chest: [
    { collectionName: "chestplate" },
    { collectionName: "chainmail" },
    { collectionName: "cuirass" },
  ],
  gloves: [
    { collectionName: "gauntlets" },
    { collectionName: "leather Gloves" },
    { collectionName: "bracers" },
  ],
  pants: [
    { collectionName: "greaves" },
    { collectionName: "cuisses" },
    { collectionName: "poleyns" },
  ],
  boots: [
    { collectionName: "combat Boots" },
    { collectionName: "sabatons" },
    { collectionName: "sneakers" },
  ],
};

// Item Rarity and Stats
export const itemCategories = [
  "weapon",
  "helmet",
  "chest",
  "gloves",
  "boots",
  "pants",
];

// Details for each item rarity. All drop chances are in percentages of 100
export const itemRarities = {
  default: {
    name: "default",
    color: "#DFDFDF",
    statValue: 0,
    weaponDropChance: 0,
    armourDropChance: 0,
    duplicateReward: 0,
  },
  common: {
    name: "common",
    color: "#353535",
    statValue: 1,
    weaponDropChance: 54.15,
    armourDropChance: 51.45,
    duplicateReward: 5,
  },
  uncommon: {
    name: "uncommon",
    color: "#2CB454",
    statValue: 2,
    weaponDropChance: 27.95,
    armourDropChance: 26.56,
    duplicateReward: 10,
  },
  rare: {
    name: "rare",
    color: "#4285f4",
    statValue: 3,
    weaponDropChance: 13.97,
    armourDropChance: 13.28,
    duplicateReward: 15,
  },
  epic: {
    name: "epic",
    color: "#A458D5",
    statValue: 4,
    weaponDropChance: 3.49,
    armourDropChance: 6.64,
    duplicateReward: 20,
  },
  legendary: {
    name: "legendary",
    color: "#FFB846",
    statValue: 5,
    weaponDropChance: 0.44,
    armourDropChance: 2.07,
    duplicateReward: 25,
  },
};

export const basicMoves: {
  [key: string]: { name: string; type: string; value: number };
} = {
  jab: { name: "jab", type: "atk", value: 1 },
  uppercut: { name: "uppercut", type: "atk", value: 2 },
  block: { name: "block", type: "blk", value: 2 },
  special: { name: "special", type: "special", value: 0 },
};

// Special Moves
type SpecialMove = {
  name: string;
  description: string;
  cost: number;
  logFunction: any;
  debuff: any;
  buff: any;
  stats: {
    enemyDamage: number;
    selfDamage: number;
    damageToBlock: number;
    heal: number;
    moveSlots: number;
  };
};

export const debuffs = {
  poison: {
    name: "poison",
    damagePerTurn: 1,
    turnDuration: 2,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const username = isOpponent ? ` ${opponentName}` : " You";
      const log = ` ${username} took ${debuffs.poison.damagePerTurn} damage from poison.`;
      return log;
    },
  },
  electricity: {
    name: "electricity",
    damagePerTurn: 2,
    turnDuration: 2,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const username = isOpponent ? ` ${opponentName}` : " You";
      const log = ` ${username} took ${debuffs.poison.damagePerTurn} damage from electricity.`;
      return log;
    },
  },
};

export const buffs = {
  doubleDamage: {
    name: "doubleDamage",
    damageMultiplier: 2,
    turnDuration: 1,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const log = isOpponent
        ? ` ${opponentName}'s move did double damage.`
        : " Your move did double damage.";
      return log;
    },
  },
};

export type SpecialMoveKeys =
  | "headbutt"
  | "bicycleKick"
  | "turtle"
  | "counter"
  | "heal"
  | "rage"
  | "lifeDrain"
  | "venomousStrike"
  | "thunderStorm"
  | "berserkFury"; // Add all your special move keys here

export const specialMoves: Record<SpecialMoveKeys, SpecialMove> = {
  headbutt: {
    name: "Headbutt",
    description:
      "Headbutt your enemy. Deals 5 damage to your foe and 3 to yourself.",
    cost: 15,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const username = isOpponent ? ` ${opponentName}` : " You";
      const log = ` ${username} inflicted ${specialMoves.headbutt.stats.selfDamage} self damage.`;
      return log;
    },
    debuff: {},
    buff: {},
    stats: {
      enemyDamage: 5,
      selfDamage: 3,
      damageToBlock: 0,
      heal: 0,
      moveSlots: 1,
    },
  },
  bicycleKick: {
    name: "Bicycle Kick",
    description:
      "Hiyah! Uses 2 move slots. Deals 3 damage per turn for the next 2 turns.",
    cost: 40,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const log = ``;
      return log;
    },
    debuff: {},
    buff: {},
    stats: {
      enemyDamage: 3,
      selfDamage: 0,
      damageToBlock: 0,
      heal: 0,
      moveSlots: 2,
    },
  },
  turtle: {
    name: "Turtle",
    description:
      "Hunker down. Uses 3 move slots. Blocks all damage for 3 turns.",
    cost: 65,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const username = isOpponent ? ` ${opponentName}` : " You";
      const log = ` ${username} blocked all damage.`;
      return log;
    },
    debuff: {},
    buff: {},
    stats: {
      enemyDamage: 0,
      selfDamage: 0,
      damageToBlock: 999,
      heal: 0,
      moveSlots: 3,
    },
  },
  counter: {
    name: "Counter",
    description: "Full counter! Uses the enemy's move on themself.",
    cost: 30,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const log = isOpponent
        ? ` ${opponentName} used your move against you.`
        : ` You used ${opponentName}'s move against them.`;
      return log;
    },
    debuff: {},
    buff: {},
    stats: {
      enemyDamage: 0,
      selfDamage: 0,
      damageToBlock: 0,
      heal: 0,
      moveSlots: 1,
    },
  },
  heal: {
    name: "Heal",
    description: "Restore 4 of your health points.",
    cost: 25,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const username = isOpponent ? ` ${opponentName}` : " You";
      const log = ` ${username} restored ${specialMoves.heal.stats.heal} health points.`;
      return log;
    },
    debuff: {},
    buff: {},
    stats: {
      enemyDamage: 0,
      selfDamage: 0,
      damageToBlock: 0,
      heal: 4,
      moveSlots: 1,
    },
  },
  rage: {
    name: "Rage",
    description: "Deals 6 damage to your opponent and 2 damage to yourself.",
    cost: 70,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const username = isOpponent ? ` ${opponentName}` : " You";
      const log = ` ${username} inflicted ${specialMoves.rage.stats.selfDamage} self damage.`;
      return log;
    },
    debuff: {},
    buff: {},
    stats: {
      enemyDamage: 6,
      selfDamage: 2,
      damageToBlock: 0,
      heal: 0,
      moveSlots: 1,
    },
  },
  lifeDrain: {
    name: "Life Drain",
    description:
      "Steals 3 health points from the opponent and heals you for 3 health points.",
    cost: 60,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const username = isOpponent ? ` ${opponentName}` : " You";
      const log = ` ${username} stole ${specialMoves.lifeDrain.stats.heal} health points and healed for the same amount.`;
      return log;
    },
    debuff: {},
    buff: {},
    stats: {
      enemyDamage: 3,
      selfDamage: 0,
      damageToBlock: 0,
      heal: 3,
      moveSlots: 1,
    },
  },
  venomousStrike: {
    name: "Venomous Strike",
    description:
      "Deals 3 damage and poisons the enemy. Poison deals 1 damage per turn for the next 2 turns.",
    cost: 50,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const log = isOpponent
        ? ` ${opponentName} has been poisoned.`
        : ` You have been poisoned.`;
      return log;
    },
    debuff: debuffs.poison,
    buff: {},
    stats: {
      enemyDamage: 3,
      selfDamage: 0,
      damageToBlock: 0,
      heal: 0,
      moveSlots: 1,
    },
  },
  thunderStorm: {
    name: "Thunderstorm",
    description: "Deals 2 damage per turn for 3 turns.",
    cost: 25,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const log = isOpponent
        ? ` ${opponentName} has been electrified.`
        : ` You have been electrified.`;
      return log;
    },
    debuff: debuffs.electricity,
    buff: {},
    stats: {
      enemyDamage: 2,
      selfDamage: 0,
      damageToBlock: 0,
      heal: 0,
      moveSlots: 1,
    },
  },
  berserkFury: {
    name: "Berserk Fury",
    description:
      "Doubles the damage of your next attack and deals 3 damage to yourself.",
    cost: 40,
    logFunction: (isOpponent: boolean, opponentName: string) => {
      const log = isOpponent
        ? ` ${opponentName}'s next attack does double damage. They infliced ${specialMoves.berserkFury.stats.selfDamage} self damage.`
        : ` Your next attack does double damage. You infliced ${specialMoves.berserkFury.stats.selfDamage} self damage.`;
      return log;
    },
    debuff: {},
    buff: buffs.doubleDamage,
    stats: {
      enemyDamage: 0,
      selfDamage: 3,
      damageToBlock: 0,
      heal: 0,
      moveSlots: 1,
    },
  },
};

// Static data for questlines
export const staticQuestData = {
  playBrawls: [
    { title: "Play 5 Brawls", requirement: 5, reward: 25 },
    { title: "Play 10 Brawls", requirement: 10, reward: 50 },
    { title: "Play 20 Brawls", requirement: 20, reward: 100 },
  ],
  startBrawls: [
    { title: "Start 5 Brawls", requirement: 5, reward: 25 },
    { title: "Start 10 Brawls", requirement: 10, reward: 50 },
    { title: "Start 20 Brawls", requirement: 20, reward: 100 },
  ],
  winBrawls: [
    { title: "Win 5 Brawls", requirement: 5, reward: 50 },
    { title: "Win 10 Brawls", requirement: 10, reward: 100 },
    { title: "Win 20 Brawls", requirement: 20, reward: 200 },
  ],
};

// Static data for weekly quests
export const staticWeeklyQuestData = {
  kingOfTheHill: { title: "Win King of the Hill", requirement: 1, reward: 250 },
  kingOfTheRing: { title: "Win King of the Ring", requirement: 1, reward: 250 },
};

// Images for the tabs in the brawl popout
export const openBrawlsTabImg = "https://i.imgur.com/ImE69RS.png";
export const createBrawlTabImg = "https://i.imgur.com/ETEpKOz.png";
export const brawlHistoryTabImg = "https://i.imgur.com/9pN4Or5.png";

// Image for the auth page for user sign in and creation
export const authHeroImg = "https://i.imgur.com/ePp2LuN.jpeg";

// Unset pics
export const unsetProfilePic = "https://i.imgur.com/F7Tyihv.jpeg";
export const unsetSpecialPic = "https://i.imgur.com/F7Tyihv.jpeg";

// Images for the move icons
export const jabMoveIcon = "https://i.imgur.com/MV49XpK.jpeg";
export const uppercutMoveIcon = "https://i.imgur.com/jxKJnGZ.jpeg";
export const blockMoveIcon = "https://i.imgur.com/32KLmP3.jpeg";
export const specialMoveIcon = "https://i.imgur.com/32bgQp2.jpeg";

// Times for brawl stages
export const vsTime = 10;
export const vsTimeAnimationOffset = 3;
export const movesTime = 30;
export const movesTimeAnimationOffset = 0;
export const brawlTime = 30;
export const brawlTimeAnimationOffset = 0;

// Hospital
export const hospitalFee = 15;

// User input checks
export const minimumWager = 1;
export const maxActiveBrawls = 3;
export const maxBrawlHistory = 10;

// Item drop chance on user victory
export const itemDropChance = 1; // between 0 and 1. The higher the number, the higher the chance. Eg: 0.25 is a 25% drop chance.

//// COLORS
// Colors for the stat boxes in the bank UI
export const weaponColor = "#0297fc";
export const armourColor = "#2ab855";

// Colors for buttons
export const successBtnColorStyle = {
  backgroundImage: "linear-gradient(to right, #2CB454, #029411)",
};
export const errorBtnColorStyle = {
  backgroundImage: "linear-gradient(to right, #ff4444, #c90707)",
};
export const normalBtnColorStyle = {
  backgroundImage: "linear-gradient(to right, #0297fc, #1184d0)",
};

// Colors for damage numbers
export const damageColor = "#ff4444";
export const blockColor = "#0297fc";
export const healColor = "#2CB454";

//// DATABASE
export const userTableName = "UserData";
export const brawlListingsTableName = "OpenBrawls";
