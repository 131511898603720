// Settings Context
import { useSettings } from "../../../../settingsContext";

// React Components
import MoveSelectPicker from "./Items/MoveSelectPicker";

// CSS
import "./move-select-screen.css";

interface MoveSelectScreenProps {
  roundTimer: number;
  selectedMoves: string[];
  setSelectedMoves: any;
}

export default function MoveSelectScreen({
  roundTimer,
  selectedMoves,
  setSelectedMoves,
}: MoveSelectScreenProps) {
  const { currentBrawlRef, userDocRef } = useSettings();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <div className="move-select-vs-container">
        <div className="move-select-profile-container">
          <img src={userDocRef.profilePic} className="move-select-vs-img"></img>
          <div className="move-select-vs-name-txt">{userDocRef.username}</div>
        </div>
        <div className="move-select-profile-container move-select-countdown-margin">
          <div className="move-select-vs-txt">Brawl begins in</div>
          <div className="move-select-vs-countdown-txt">
            {roundTimer} seconds
          </div>
        </div>
        <div className="move-select-profile-container">
          <img
            src={
              currentBrawlRef.live
                ? currentBrawlRef.player1ProfilePic
                : currentBrawlRef.player2ProfilePic
            }
            className="move-select-vs-img"
          ></img>
          <div className="move-select-vs-name-txt">
            {currentBrawlRef.live
              ? currentBrawlRef.player1Username
              : currentBrawlRef.player2Username}
          </div>
        </div>
      </div>
      <div className="move-select-screen-body">
        <MoveSelectPicker
          selectedMoves={selectedMoves}
          setSelectedMoves={setSelectedMoves}
        />
      </div>
    </div>
  );
}
