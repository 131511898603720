// React
import { useState } from "react";

// CSS
import "./transition-screen.css";

interface TransitionScreenProps {
  transitionActive: boolean;
  startOnCreation: boolean;
}

export default function TransitionScreen({
  transitionActive,
  startOnCreation,
}: TransitionScreenProps) {
  const [start, setStart] = useState(startOnCreation);

  const TriggerStartOnCreation = () => {
    setTimeout(() => {
      setStart(false);
    }, 1000);

    return (
      <div
        className={`transition-screen-body ${
          transitionActive ? "close" : "open"
        }`}
      />
    );
  };

  return (
    <div>
      {start ? (
        TriggerStartOnCreation()
      ) : (
        <div
          className={`transition-screen-body ${
            transitionActive ? "open" : "close"
          }`}
        />
      )}
    </div>
  );
}
