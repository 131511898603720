// React
import { useState } from "react";

// React Components
import ImageUpload from "../../ImageUpload";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Firebase
import { UpdateDocumentOnFirestore } from "../../../firebaseconfig";

// Constants
import {
  errorBtnColorStyle,
  normalBtnColorStyle,
  successBtnColorStyle,
  userTableName,
} from "../../../Constants";

// CSS
import "./account-manager.css";

export default function AccountManager() {
  const { userDocRef } = useSettings();
  const [newProfilePic, setNewProfilePic] = useState(userDocRef.profilePic);
  const [newUsername, setNewUsername] = useState(userDocRef.username);
  const [newTwitter, setNewTwitter] = useState(userDocRef.twitter);
  const [newBio, setNewBio] = useState(userDocRef.bio);
  const [newPassword, setNewPassword] = useState(userDocRef.profilePic);
  const [btnMode, setBtnMode] = useState("normal");

  const UpdateAccountData = async (userID: string, newData: any) => {
    if (
      newProfilePic !== userDocRef.profilePic ||
      newUsername !== userDocRef.username ||
      newTwitter !== userDocRef.twitter ||
      newBio !== userDocRef.bio
    ) {
      if (newUsername !== "") {
        await UpdateDocumentOnFirestore(userTableName, userID, newData);

        setBtnMode("success");
        setTimeout(() => {
          // This code will run after 1000 milliseconds (1 second)
          setBtnMode("normal");
        }, 3000);
      } else {
        setBtnMode("error");
        setTimeout(() => {
          // This code will run after 1000 milliseconds (1 second)
          setBtnMode("normal");
        }, 3000);
      }
    }
  };

  return (
    <div className="account-manager-div">
      <h1 className="account-manager-username-txt">{userDocRef.username}</h1>
      <div className="account-manager-change-profile-pic-container">
        <ImageUpload
          profilePic={newProfilePic}
          setProfilePic={setNewProfilePic}
          size={13}
        />
        <h3 className="account-manager-change-profile-pic-txt">
          Click on your profile pic to change it.
        </h3>
      </div>
      <label className="account-manager-label">Username</label>
      <input
        type="text"
        value={newUsername}
        onChange={(event) => {
          setNewUsername(event.target.value);
        }}
        className="account-manager-input"
      ></input>
      <label className="account-manager-label">Twitter</label>
      <input
        type="text"
        value={newTwitter}
        onChange={(event) => {
          setNewTwitter(event.target.value);
        }}
        className="account-manager-input"
      ></input>
      <label className="account-manager-label">Password</label>
      <button
        onClick={() => {}}
        className="account-manager-change-password-btn"
      >
        Change Password
      </button>
      <label className="account-manager-label">Bio</label>
      <textarea
        rows={3}
        value={newBio}
        onChange={(event) => {
          setNewBio(event.target.value);
        }}
        className="account-manager-text-area"
      ></textarea>
      <button
        onClick={async () => {
          await UpdateAccountData(userDocRef.userID, {
            username: newUsername,
            twitter: newTwitter,
            bio: newBio,
            profilePic: newProfilePic,
          });
        }}
        disabled={btnMode === "normal" || btnMode === "success" ? false : true}
        className="account-manager-confirm-btn"
        style={
          btnMode === "normal"
            ? normalBtnColorStyle
            : btnMode === "error"
            ? errorBtnColorStyle
            : successBtnColorStyle
        }
      >
        {btnMode === "error"
          ? "Username cannot be empty"
          : btnMode === "success"
          ? "Account changed successfully"
          : "Confirm All Changes"}
      </button>
    </div>
  );
}
