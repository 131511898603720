import react, { useState } from "react";
import MoveSelectScreen from "../components/BrawlVisuals/Screens/moveSelectScreen/MoveSelectScreen";

export default function TestingPage() {
  const [selectedMoves, setSelectedMoves] = useState([
    "???",
    "???",
    "???",
    "???",
    "???",
    "???",
  ]);

  return (
    <div
      style={{ height: "100vh", width: "100vw", backgroundColor: "lightblue" }}
    >
      <MoveSelectScreen
        roundTimer={1}
        selectedMoves={selectedMoves}
        setSelectedMoves={setSelectedMoves}
      />
    </div>
  );
}
