// React
import { useNavigate } from "react-router-dom";

// Global Functions
import {
  getRarityColor,
  getStatValue,
  capitalizeFirstLetter,
} from "../../../globalFunctions";

// Constants
import { armourColor, itemRarities, weaponColor } from "../../../Constants";

// Settings Context
import { useSettings } from "../../../settingsContext";

// CSS
import "./brawl-overview-popup.css";

interface BrawlOverviewPopupProps {
  userWon: string;
  overviewText: any;
  earnedItemData: any;
  showOverviewPopup: boolean;
}

export default function BrawlOverviewPopup({
  userWon,
  overviewText,
  earnedItemData,
  showOverviewPopup,
}: BrawlOverviewPopupProps) {
  const { setTransitionActive } = useSettings();

  const navigate = useNavigate();

  const ReturnToHome = () => {
    setTransitionActive(true);
    setTimeout(() => {
      setTransitionActive(false);
      navigate("/home");
    }, 1000);
  };

  return (
    <div
      className={`brawl-overview-popup-backdrop ${
        showOverviewPopup ? "open" : "close"
      }`}
    >
      <div
        className={`brawl-overview-popup-body ${
          showOverviewPopup ? "open" : "close"
        }`}
      >
        <h1 className="brawl-overview-popup-heading">
          You{" "}
          {userWon === "win"
            ? "Won!"
            : userWon === "tie"
            ? "Tied!"
            : userWon === "loss"
            ? "Lost!"
            : "{ERROR}"}
        </h1>
        <p className="brawl-overview-popup-txt">{overviewText}</p>
        {earnedItemData.type !== "null" && userWon === "win" ? (
          <div>
            <div className="brawl-popup-overview-earned-txt">You earned:</div>
            <div className="brawl-overview-popup-popup-container">
              <div className="brawl-overview-popup-row">
                <div
                  className="brawl-overview-popup-img-container"
                  style={{
                    backgroundColor: getRarityColor(earnedItemData.rarity),
                  }}
                >
                  <img src=""></img>
                  <div
                    className="brawl-overview-popup-stat-txt"
                    style={
                      earnedItemData.type === "weapon"
                        ? { backgroundColor: weaponColor }
                        : { backgroundColor: armourColor }
                    }
                  >
                    +{getStatValue(earnedItemData.rarity)}{" "}
                    {earnedItemData.type === "weapon" ? " damage" : " health"}
                  </div>
                </div>
                <div>
                  <div className="brawl-overview-popup-type-txt">
                    {capitalizeFirstLetter(earnedItemData.type)}
                  </div>
                  <div className="brawl-overview-popup-collection-txt">
                    {capitalizeFirstLetter(earnedItemData.collection)}
                  </div>
                  <div className="brawl-overview-popup-rarity-txt">
                    {capitalizeFirstLetter(earnedItemData.rarity)}
                  </div>
                </div>
              </div>
              <div className="brawl-overview-popup-equip-txt">
                {earnedItemData.dupe
                  ? `This item is a duplicate of one you already own. For its rarity, you have been awarded ${
                      itemRarities[
                        earnedItemData.rarity as keyof typeof itemRarities
                      ].duplicateReward
                    } $Brawl.`
                  : earnedItemData.replace
                  ? "This item has a higher rarity than the item currently being used and has therefore been equipped."
                  : "This item is inferior or on par with the item you are currently using. No changes have been made."}
              </div>
            </div>
          </div>
        ) : null}

        <button
          onClick={() => {
            ReturnToHome();
          }}
          className="brawl-overview-popup-btn"
        >
          Return
        </button>
      </div>
    </div>
  );
}
