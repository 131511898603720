// React
import { useEffect, useState } from "react";

// React Components
import OpenBrawlItem from "../Items/OpenBrawlItem";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Constants
import { brawlListingsTableName, maxActiveBrawls } from "../../../Constants";

// Firebase
import { DeleteDocumentFromFirestore } from "../../../firebaseconfig";

// CSS
import "./open-brawls.css";

export default function OpenBrawls() {
  const { userDocRef, openBrawls, showBrawlPopout } = useSettings();

  const [brawlsActive, setBrawlsActive] = useState<any[]>([]);

  useEffect(() => {
    setBrawlsActive(FindActiveBrawls());
  }, []);

  useEffect(() => {
    if (showBrawlPopout === true) {
      setBrawlsActive(FindActiveBrawls());
    }
  }, [showBrawlPopout]);

  const FindActiveBrawls = () => {
    // Filter openBrawls to get all brawls with a matching userID
    const owned = openBrawls.filter(
      (brawl: any) => brawl.data().player1UserID === userDocRef.userID
    );

    // Map over the filtered brawls and add the docId field
    const ownedWithDocId = owned.map((brawl: any) => {
      const brawlData = brawl.data();
      brawlData["docId"] = brawl.ref.id; // Assuming brawl.ref.id is the correct way to get the docId
      return brawlData;
    });

    // Return the list of owned brawls with docId
    return ownedWithDocId;
  };

  const RemoveBrawls = async () => {
    // Create an array of promises for deleting each brawl
    const deletePromises = brawlsActive.map((brawl) =>
      DeleteDocumentFromFirestore(brawlListingsTableName, brawl.docId)
    );

    // Wait for all deletions to complete
    await Promise.all(deletePromises);

    setBrawlsActive([]);
  };

  return (
    <div>
      <div className="open-brawls-div">
        <div className="open-brawls-title-container">
          <h4 className="open-brawls-title-txt">Open Brawls</h4>
        </div>
        <div className="open-brawls-item-container">
          {openBrawls.length === 0 ? (
            <div className="open-brawls-empty-message">
              <hr className="open-brawls-break-line" />
              <h5 className="open-brawls-empty-txt">
                There are no open brawls
              </h5>
            </div>
          ) : (
            openBrawls.map((rawBrawlData: any, index: number) => (
              <OpenBrawlItem key={index} rawBrawlData={rawBrawlData} />
            ))
          )}
        </div>
      </div>
      <div className="open-brawls-active-container">
        <div className="open-brawls-active-body">
          <div className="open-brawls-active-details">
            <div className="open-brawls-active-count-txt">
              {brawlsActive.length}
              <span style={{ fontSize: "24px" }}>
                /{maxActiveBrawls} Active Brawls
              </span>
            </div>
          </div>
          <button
            onClick={async () => {
              console.log("Clicked");
              await RemoveBrawls();
            }}
            className="open-brawls-active-remove-btn"
          >
            Close Brawls
          </button>
        </div>
      </div>
    </div>
  );
}
