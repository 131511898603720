// Constants
import { itemRarities } from "./Constants";

export const toCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word; // Keep the first word in lowercase
      }
      return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter of subsequent words
    })
    .join(""); // Join all the words together
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getRarityColor = (itemRarity: string) => {
  if (!itemRarity) {
    return "white";
  }

  return (
    itemRarities[itemRarity as keyof typeof itemRarities].color ||
    itemRarities["default"].color
  );
};

export const getStatValue = (rarity: string) => {
  return itemRarities[rarity as keyof typeof itemRarities].statValue || 0; // Default value if not found
};
