import { useState } from "react";
import SignInUI from "../components/Auth/SignInUI";
import CreateUserUI from "../components/Auth/CreateUserUI";
import SetupAccountUI from "../components/Auth/SetupAccountUI";
import AuthHeroImage from "../components/Auth/AuthHeroImage";
import "./user-auth-page.css";

export default function UserAuthPage() {
  const [activeComponent, setActiveComponent] = useState("signin");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="auth-page-div">
      <h1 className="auth-page-logo">BrawlClub</h1>
      <div className="auth-console-container">
        {activeComponent === "signin" ? (
          <SignInUI
            setActiveComponent={setActiveComponent}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        ) : activeComponent === "createuser" ? (
          <CreateUserUI
            setActiveComponent={setActiveComponent}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        ) : activeComponent === "setupaccount" ? (
          <SetupAccountUI
            setActiveComponent={setActiveComponent}
            email={email}
            password={password}
          />
        ) : null}
      </div>
      <AuthHeroImage />
    </div>
  );
}
