// React
import { useEffect, useState } from "react";

// React Components
import EditRound from "../Interactables/EditRound";
import MoveSelectButton from "../Interactables/MoveSelectButton";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Constants
import {
  blockMoveIcon,
  jabMoveIcon,
  maxActiveBrawls,
  maxBrawlHistory,
  minimumWager,
  specialMoveIcon,
  specialMoves,
  staticQuestData,
  uppercutMoveIcon,
  errorBtnColorStyle,
  normalBtnColorStyle,
  successBtnColorStyle,
  brawlListingsTableName,
  userTableName,
} from "../../../Constants";

// Global Functions
import { toCamelCase } from "../../../globalFunctions";

// Firestore
import {
  SaveDataToFirestore,
  UpdateDocumentOnFirestore,
} from "../../../firebaseconfig";
import { increment } from "firebase/firestore";

// CSS
import "./create-brawl.css";

export default function CreateBrawl() {
  // From settings context
  const { openBrawls, userDocRef } = useSettings();

  // State variables
  const [selectedMoves, setSelectedMoves] = useState([
    "???",
    "???",
    "???",
    "???",
    "???",
    "???",
  ]);

  // Control if moves are disabled in that round
  const [isUppercutDisabled, setUppercutDisabled] = useState(false);
  const [isBlockDisabled, setBlockDisabled] = useState(false);
  const [isSpecialDisabled, setSpecialDisabled] = useState(false);

  // Decides if the button should show an error, success or normal message
  const [btnMode, setBtnMode] = useState("normal");
  const [errorMessage, setErrorMessage] = useState("");

  // Stores information that the user enters
  const [wagerAmount, setWagerAmount] = useState(0);
  const [currentRound, setCurrentRound] = useState(1);

  useEffect(() => {
    if (
      (selectedMoves[0] === "uppercut" && currentRound === 2) ||
      (selectedMoves[1] === "uppercut" && currentRound === 1) ||
      (selectedMoves[1] === "uppercut" && currentRound === 3) ||
      (selectedMoves[2] === "uppercut" && currentRound === 2) ||
      (selectedMoves[2] === "uppercut" && currentRound === 4) ||
      (selectedMoves[3] === "uppercut" && currentRound === 3) ||
      (selectedMoves[3] === "uppercut" && currentRound === 5) ||
      (selectedMoves[4] === "uppercut" && currentRound === 4) ||
      (selectedMoves[4] === "uppercut" && currentRound === 6) ||
      (selectedMoves[5] === "uppercut" && currentRound === 5)
    ) {
      setUppercutDisabled(true);
    } else {
      setUppercutDisabled(false);
    }

    if (
      (selectedMoves[0] === "block" && currentRound === 2) ||
      (selectedMoves[1] === "block" && currentRound === 1) ||
      (selectedMoves[1] === "block" && currentRound === 3) ||
      (selectedMoves[2] === "block" && currentRound === 2) ||
      (selectedMoves[2] === "block" && currentRound === 4) ||
      (selectedMoves[3] === "block" && currentRound === 3) ||
      (selectedMoves[3] === "block" && currentRound === 5) ||
      (selectedMoves[4] === "block" && currentRound === 4) ||
      (selectedMoves[4] === "block" && currentRound === 6) ||
      (selectedMoves[5] === "block" && currentRound === 5)
    ) {
      setBlockDisabled(true);
    } else {
      setBlockDisabled(false);
    }

    //@ts-ignore
    const specialMove = specialMoves[toCamelCase(userDocRef.specialMove)];

    if (currentRound - 1 + specialMove?.stats.moveSlots > 6) {
      setSpecialDisabled(true); // Disable the special move
    } else if (
      selectedMoves.every((move) =>
        ["jab", "uppercut", "block", "???"].includes(move)
      )
    ) {
      setSpecialDisabled(false); // Enable the special move
    } else {
      setSpecialDisabled(true); // Disable the special move
    }
  }, [selectedMoves, currentRound]);

  const UpdateMoves = (move: string, round: number) => {
    const newMoves = [...selectedMoves];
    //@ts-ignore
    let specialMove;
    let moveSlots = 0;
    if (userDocRef.specialMove !== "") {
      specialMove =
        specialMoves[
          toCamelCase(userDocRef.specialMove) as keyof typeof specialMoves
        ];
      moveSlots = specialMove.stats.moveSlots;
    }

    const updateSpecialMove = (start: number) => {
      for (let i = start; i < start + moveSlots; i++) {
        newMoves[i] = move;
      }
    };

    const updateRegularMove = (index: number) => {
      for (let i = 0; i < 6; i++) {
        if (selectedMoves[i] === "special") {
          newMoves[i] = i === index ? move : "???";
        }
      }
    };

    const regularMoveCheck = (index: number) => {
      return (
        selectedMoves[index] === "special" ||
        selectedMoves[index + 1] === "special"
      );
    };

    switch (round) {
      case 1:
        move === "special"
          ? updateSpecialMove(0)
          : regularMoveCheck(0)
          ? updateRegularMove(0)
          : (newMoves[0] = move);
        break;
      case 2:
        move === "special"
          ? updateSpecialMove(1)
          : regularMoveCheck(1)
          ? updateRegularMove(1)
          : (newMoves[1] = move);
        break;
      case 3:
        move === "special"
          ? updateSpecialMove(2)
          : regularMoveCheck(2)
          ? updateRegularMove(2)
          : (newMoves[2] = move);
        break;
      case 4:
        move === "special"
          ? updateSpecialMove(3)
          : regularMoveCheck(3)
          ? updateRegularMove(3)
          : (newMoves[3] = move);
        break;
      case 5:
        move === "special"
          ? updateSpecialMove(4)
          : regularMoveCheck(4)
          ? updateRegularMove(4)
          : (newMoves[4] = move);
        break;
      case 6:
        move === "special"
          ? updateSpecialMove(5)
          : selectedMoves[5] === "special"
          ? updateRegularMove(5)
          : (newMoves[5] = move);
        break;
      default:
        move === "special"
          ? updateSpecialMove(0)
          : regularMoveCheck(0)
          ? updateRegularMove(0)
          : (newMoves[0] = move);
        break;
    }

    setSelectedMoves(newMoves);

    if (currentRound < 6) {
      if (move === "special" && currentRound - 1 + moveSlots <= 6) {
        setCurrentRound(round + moveSlots);
      } else {
        setCurrentRound(round + 1);
      }
    }
  };

  const FindActiveQuest = () => {
    const startBrawlQuests = userDocRef.questLines.startBrawls;

    const selectedQuest = startBrawlQuests.find(
      (questData: any) => !questData.claimed
    );

    if (
      selectedQuest &&
      selectedQuest.progress !==
        staticQuestData.startBrawls[startBrawlQuests.indexOf(selectedQuest)]
          .requirement
    ) {
      const questIndex =
        userDocRef.questLines["startBrawls"].indexOf(selectedQuest);
      userDocRef.questLines["startBrawls"][questIndex].progress += 1;
    }
  };

  const CountActiveBrawls = () => {
    // Filter openBrawls to get all brawls with a matching userID
    const owned = openBrawls.filter(
      (brawl: any) => brawl.data().player1UserID === userDocRef.userID
    );

    // Return the list of owned brawls with docId
    return owned.length;
  };

  const StartBrawl = async () => {
    const activeBrawls = CountActiveBrawls();
    const hasUnsetMoves = selectedMoves.includes("???");
    const {
      $Brawl,
      username,
      profilePic,
      userID,
      wins,
      losses,
      health,
      maxHealth,
      specialMove,
      questLines,
    } = userDocRef;

    if (
      wagerAmount >= minimumWager &&
      wagerAmount <= $Brawl &&
      !hasUnsetMoves &&
      activeBrawls < maxActiveBrawls &&
      userDocRef.brawlHistory.length < maxBrawlHistory &&
      userDocRef.health > 0
    ) {
      FindActiveQuest();

      const brawlData = {
        // Profile
        player1Username: username,
        player1ProfilePic: profilePic,
        player1UserID: userID,

        // Stats
        player1Wins: wins,
        player1Losses: losses,

        // Moves
        player1Moves: selectedMoves,
        player1SpecialMove: specialMove,

        // Status
        player1Health: health,
        player1MaxHealth: maxHealth,
        player1WeaponRarity: userDocRef.equippedItems.weapon.itemRarity,

        // General
        wagerAmount: wagerAmount,
        live: true,
      };

      setBtnMode("starting");

      await SaveDataToFirestore(brawlListingsTableName, "", brawlData);
      await UpdateDocumentOnFirestore(userTableName, userID, {
        $Brawl: increment(-wagerAmount),
        "questLines.startBrawls": questLines.startBrawls,
      });

      setBtnMode("success");
      setTimeout(() => setBtnMode("normal"), 3000);
    } else {
      let errorMessage = "";
      if ($Brawl < wagerAmount) {
        errorMessage = "Insufficient $Brawl for Wager";
      } else if (wagerAmount < minimumWager) {
        errorMessage = "Wager cannot be 0 $Brawl";
      } else if (Number.isNaN(wagerAmount)) {
        errorMessage = "Please set a wager";
      } else if (hasUnsetMoves) {
        errorMessage = "You have unset moves";
      } else if (activeBrawls === maxActiveBrawls) {
        errorMessage = "You cannot have more than 3 active brawls";
      } else if (userDocRef.brawlHistory.length === maxBrawlHistory) {
        errorMessage = "Your brawl history is full. Clear it out";
      } else if (userDocRef.health <= 0) {
        errorMessage = "Your health cannot be 0";
      }

      setErrorMessage(errorMessage);
      setBtnMode("error");
      setTimeout(() => setBtnMode("normal"), 3000);
    }
  };

  return (
    <div className="create-brawl-div">
      <div className="create-brawl-top-container ">
        <div className="create-brawl-title-container">
          <h4 className="create-brawl-title-txt">Start a Brawl</h4>
        </div>
        <div className="create-brawl-wager-container">
          <div>
            <label className="create-brawl-wager-txt">Place your wager:</label>
            <input
              type="number"
              className="create-brawl-input"
              value={wagerAmount}
              onChange={(event) => {
                setWagerAmount(parseInt(event.target.value));
              }}
            ></input>
          </div>

          <div>
            <label className="create-brawl-wager-txt">
              Potential earnings:
            </label>
            <p className="create-brawl-wager-earnings-txt">
              {wagerAmount * 2} $Brawl
            </p>
          </div>
        </div>
      </div>
      <div className="create-brawl-bottom-container">
        <label className="create-brawl-round-txt">Round {currentRound}/6</label>
        <div className="create-brawl-select-move-container">
          <MoveSelectButton
            UpdateMoves={UpdateMoves}
            currentRound={currentRound}
            disabled={false}
            move="Jab"
            moveImage={jabMoveIcon}
          />
          <MoveSelectButton
            UpdateMoves={UpdateMoves}
            currentRound={currentRound}
            disabled={isUppercutDisabled}
            move="Uppercut"
            moveImage={uppercutMoveIcon}
          />
          <MoveSelectButton
            UpdateMoves={UpdateMoves}
            currentRound={currentRound}
            disabled={isBlockDisabled}
            move="Block"
            moveImage={blockMoveIcon}
          />
          <MoveSelectButton
            UpdateMoves={UpdateMoves}
            currentRound={currentRound}
            disabled={userDocRef.specialMove !== "" ? isSpecialDisabled : true}
            move="Special"
            moveImage={specialMoveIcon}
          />
        </div>
        <div>
          {Array.from({ length: 6 }, (_, index) => (
            <EditRound
              key={index + 1}
              selectedMoves={selectedMoves}
              setCurrentRound={setCurrentRound}
              round={index + 1}
            />
          ))}
        </div>
      </div>
      <button
        onClick={async () => {
          await StartBrawl();
        }}
        disabled={
          btnMode === "normal"
            ? false
            : btnMode === "success" ||
              btnMode === "error" ||
              btnMode === "starting"
            ? true
            : false
        }
        className="create-brawl-start-btn"
        style={
          btnMode === "normal"
            ? normalBtnColorStyle
            : btnMode === "error"
            ? errorBtnColorStyle
            : btnMode === "success"
            ? successBtnColorStyle
            : normalBtnColorStyle
        }
      >
        {btnMode === "error"
          ? errorMessage
          : btnMode === "success"
          ? "Brawl started successfully"
          : btnMode === "normal"
          ? "Start Brawl"
          : btnMode === "starting"
          ? "Starting..."
          : "Start Brawl"}
      </button>
    </div>
  );
}
