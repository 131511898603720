// React
import { useEffect, useState } from "react";

// React Components
import MoveSelectButton from "../../../../BrawlPopout/Interactables/MoveSelectButton";
import MoveSelectEditBtn from "../Interactables/MoveSelectEditButton";

// Settings Context
import { useSettings } from "../../../../../settingsContext";

// Constants
import {
  blockMoveIcon,
  jabMoveIcon,
  specialMoveIcon,
  specialMoves,
  uppercutMoveIcon,
} from "../../../../../Constants";

// Functions
import { toCamelCase } from "../../../../../globalFunctions";

// CSS
import "./move-select-picker.css";

interface MoveSelectPickerProps {
  selectedMoves: string[];
  setSelectedMoves: any;
}

export default function MoveSelectPicker({
  selectedMoves,
  setSelectedMoves,
}: MoveSelectPickerProps) {
  const { userDocRef } = useSettings();

  // Governs if a move is unable to be picked during the current round
  const [isUppercutDisabled, setUppercutDisabled] = useState(false);
  const [isBlockDisabled, setBlockDisabled] = useState(false);
  const [isSpecialDisabled, setSpecialDisabled] = useState(false);

  // Current round tracker
  const [currentRound, setCurrentRound] = useState(1);

  useEffect(() => {
    if (
      (selectedMoves[0] === "uppercut" && currentRound === 2) ||
      (selectedMoves[1] === "uppercut" && currentRound === 1) ||
      (selectedMoves[1] === "uppercut" && currentRound === 3) ||
      (selectedMoves[2] === "uppercut" && currentRound === 2) ||
      (selectedMoves[2] === "uppercut" && currentRound === 4) ||
      (selectedMoves[3] === "uppercut" && currentRound === 3) ||
      (selectedMoves[3] === "uppercut" && currentRound === 5) ||
      (selectedMoves[4] === "uppercut" && currentRound === 4) ||
      (selectedMoves[4] === "uppercut" && currentRound === 6) ||
      (selectedMoves[5] === "uppercut" && currentRound === 5)
    ) {
      setUppercutDisabled(true);
    } else {
      setUppercutDisabled(false);
    }

    if (
      (selectedMoves[0] === "block" && currentRound === 2) ||
      (selectedMoves[1] === "block" && currentRound === 1) ||
      (selectedMoves[1] === "block" && currentRound === 3) ||
      (selectedMoves[2] === "block" && currentRound === 2) ||
      (selectedMoves[2] === "block" && currentRound === 4) ||
      (selectedMoves[3] === "block" && currentRound === 3) ||
      (selectedMoves[3] === "block" && currentRound === 5) ||
      (selectedMoves[4] === "block" && currentRound === 4) ||
      (selectedMoves[4] === "block" && currentRound === 6) ||
      (selectedMoves[5] === "block" && currentRound === 5)
    ) {
      setBlockDisabled(true);
    } else {
      setBlockDisabled(false);
    }

    //@ts-ignore
    const specialMove = specialMoves[toCamelCase(userDocRef.specialMove)];

    if (currentRound - 1 + specialMove?.stats.moveSlots > 6) {
      setSpecialDisabled(true); // Disable the special move
    } else if (
      selectedMoves.every((move) =>
        ["jab", "uppercut", "block", "???"].includes(move)
      )
    ) {
      setSpecialDisabled(false); // Enable the special move
    } else {
      setSpecialDisabled(true); // Disable the special move
    }
  }, [selectedMoves, currentRound]);

  const UpdateMoves = (move: string, round: number) => {
    const newMoves = [...selectedMoves];
    //@ts-ignore
    let specialMove;
    let moveSlots = 0;
    if (userDocRef.specialMove !== "") {
      specialMove =
        specialMoves[
          toCamelCase(userDocRef.specialMove) as keyof typeof specialMoves
        ];
      moveSlots = specialMove.stats.moveSlots;
    }

    const updateSpecialMove = (start: number) => {
      for (let i = start; i < start + moveSlots; i++) {
        newMoves[i] = move;
      }
    };

    const updateRegularMove = (index: number) => {
      for (let i = 0; i < 6; i++) {
        if (selectedMoves[i] === "special") {
          newMoves[i] = i === index ? move : "???";
        }
      }
    };

    const regularMoveCheck = (index: number) => {
      return (
        selectedMoves[index] === "special" ||
        selectedMoves[index + 1] === "special"
      );
    };

    switch (round) {
      case 1:
        move === "special"
          ? updateSpecialMove(0)
          : regularMoveCheck(0)
          ? updateRegularMove(0)
          : (newMoves[0] = move);
        break;
      case 2:
        move === "special"
          ? updateSpecialMove(1)
          : regularMoveCheck(1)
          ? updateRegularMove(1)
          : (newMoves[1] = move);
        break;
      case 3:
        move === "special"
          ? updateSpecialMove(2)
          : regularMoveCheck(2)
          ? updateRegularMove(2)
          : (newMoves[2] = move);
        break;
      case 4:
        move === "special"
          ? updateSpecialMove(3)
          : regularMoveCheck(3)
          ? updateRegularMove(3)
          : (newMoves[3] = move);
        break;
      case 5:
        move === "special"
          ? updateSpecialMove(4)
          : regularMoveCheck(4)
          ? updateRegularMove(4)
          : (newMoves[4] = move);
        break;
      case 6:
        move === "special"
          ? updateSpecialMove(5)
          : selectedMoves[5] === "special"
          ? updateRegularMove(5)
          : (newMoves[5] = move);
        break;
      default:
        move === "special"
          ? updateSpecialMove(0)
          : regularMoveCheck(0)
          ? updateRegularMove(0)
          : (newMoves[0] = move);
        break;
    }

    setSelectedMoves(newMoves);

    if (currentRound < 6) {
      if (move === "special" && currentRound - 1 + moveSlots <= 6) {
        setCurrentRound(round + moveSlots);
      } else {
        setCurrentRound(round + 1);
      }
    }
  };

  return (
    <div className="move-select-picker-body">
      <div className="move-select-picker-round-display">
        Round {currentRound}/6
      </div>
      <div className="move-select-picker-top-container">
        <div>
          <MoveSelectButton
            UpdateMoves={UpdateMoves}
            currentRound={currentRound}
            disabled={false}
            move="Jab"
            moveImage={jabMoveIcon}
          />
          <MoveSelectButton
            UpdateMoves={UpdateMoves}
            currentRound={currentRound}
            disabled={isUppercutDisabled}
            move="Uppercut"
            moveImage={uppercutMoveIcon}
          />
          <MoveSelectButton
            UpdateMoves={UpdateMoves}
            currentRound={currentRound}
            disabled={isBlockDisabled}
            move="Block"
            moveImage={blockMoveIcon}
          />
          <MoveSelectButton
            UpdateMoves={UpdateMoves}
            currentRound={currentRound}
            disabled={userDocRef.specialMove !== "" ? isSpecialDisabled : true}
            move="Special"
            moveImage={specialMoveIcon}
          />
        </div>
      </div>
      <div className="move-select-picker-bottom-container">
        <div className="move-select-picker-btn-container">
          <MoveSelectEditBtn
            move={selectedMoves[0]}
            round={1}
            setCurrentRound={setCurrentRound}
          />
          <MoveSelectEditBtn
            move={selectedMoves[1]}
            round={2}
            setCurrentRound={setCurrentRound}
          />
          <MoveSelectEditBtn
            move={selectedMoves[2]}
            round={3}
            setCurrentRound={setCurrentRound}
          />
          <MoveSelectEditBtn
            move={selectedMoves[3]}
            round={4}
            setCurrentRound={setCurrentRound}
          />
          <MoveSelectEditBtn
            move={selectedMoves[4]}
            round={5}
            setCurrentRound={setCurrentRound}
          />
          <MoveSelectEditBtn
            move={selectedMoves[5]}
            round={6}
            setCurrentRound={setCurrentRound}
          />
        </div>
      </div>
    </div>
  );
}
