// React
import { useState } from "react";

// React Components
import RankingList from "./Tabs/RankingList";

// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import { createBrawlTabImg, openBrawlsTabImg } from "../../Constants";

// CSS
import "./leaderboard-popout.css";

export default function LeaderboardPopout() {
  const { showLeaderboardPopout, setShowLeaderboardPopout } = useSettings();

  const [activeTab, setActiveTab] = useState("brawl");

  return (
    <div
      className={`leaderboard-popout-div ${
        showLeaderboardPopout ? "open" : "close"
      }`}
    >
      <div className="leaderboard-popout-tab-container">
        <button
          onClick={() => {
            setShowLeaderboardPopout(false);
          }}
          className="leaderboard-popout-close-btn"
        >
          <b>X</b>
        </button>
        <button
          onClick={() => {
            setActiveTab("brawl");
          }}
          className="leaderboard-popout-tab-btn"
          style={{
            borderTopLeftRadius: "1rem",
            borderBottomLeftRadius: "1rem",
          }}
        >
          <div className="leaderboard-popout-tab-overlay" />
          <img
            src={openBrawlsTabImg}
            className="leaderboard-popout-tab-img"
          ></img>
          <p className="leaderboard-popout-tab-txt">By $Brawl</p>
        </button>
        <button
          onClick={() => {
            setActiveTab("wins");
          }}
          className="leaderboard-popout-tab-btn"
          style={{
            borderTopRightRadius: "1rem",
            borderBottomRightRadius: "1rem",
          }}
        >
          <div className="leaderboard-popout-tab-overlay" />
          <img
            src={createBrawlTabImg}
            className="leaderboard-popout-tab-img"
          ></img>
          <p className="leaderboard-popout-tab-txt">By Wins</p>
        </button>
      </div>
      {activeTab === "brawl" ? (
        <RankingList type={"brawl"} />
      ) : activeTab === "wins" ? (
        <RankingList type={"wins"} />
      ) : null}
    </div>
  );
}
