// React
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// React Components
import ImageUpload from "../ImageUpload";

// Firebase
import { CreateNewUser } from "../../firebaseconfig";

// Settings Context
import { useSettings } from "../../settingsContext";

// Constants
import { unsetProfilePic } from "../../Constants";

// CSS
import "./setup-account-ui.css";

interface SetupAccountProps {
  setActiveComponent: any;
  email: string;
  password: string;
}

export default function SetupAccountUI({
  setActiveComponent,
  email,
  password,
}: SetupAccountProps) {
  const [profilePic, setProfilePic] = useState<any>(unsetProfilePic);
  const [username, setUsername] = useState("");
  const [twitter, setTwitter] = useState("");
  const [bio, setBio] = useState("");
  const [warningMessage, setWarningMessage] = useState(
    "Username cannot be blank."
  );
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const { setUserDocRef } = useSettings();

  const navigate = useNavigate();

  const TriggerCreateUser = async (email: string, password: string) => {
    if (username !== "" && username.length <= 10 && username.length >= 3) {
      try {
        const result = await CreateNewUser(
          email,
          password,
          username,
          twitter,
          bio,
          profilePic
        );
        if (result.success === true) {
          setUserDocRef(result.docRef);
          navigate("/home");
        }
      } catch (error) {
        console.log(error);
        setWarningMessage("Failed to create account. Please try again.");
        setShowWarningMessage(true);
      }
    } else if (username === "") {
      setWarningMessage("Username cannot be blank.");
      setShowWarningMessage(true);
    } else if (username.length > 10) {
      setWarningMessage("Username cannot exceed 10 characters.");
      setShowWarningMessage(true);
    } else if (username.length < 3) {
      setWarningMessage("Username must be at least 3 characters.");
      setShowWarningMessage(true);
    }
  };

  return (
    <div>
      <div>
        <h1 className="setup-account-title">SETUP YOUR BRAWLCLUB ACCOUNT</h1>
        <div className="setup-account-profile-pic-container">
          <ImageUpload
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            size={10}
          />
          <label htmlFor="text-input">Click to Change Profile Pic</label>
        </div>
        <div>
          <div>
            <label className="form-label input-margin">Username</label>
            <input
              type="text"
              className="form-control"
              placeholder="Eg: BigBully"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            ></input>
          </div>
          <div>
            <label className="form-label input-margin">Twitter</label>
            <input
              type="text"
              className="form-control"
              placeholder="@twitteruser (optional)"
              value={twitter}
              onChange={(event) => {
                setTwitter(event.target.value);
              }}
            ></input>
            <label className="form-label input-margin">Bio</label>
            <input
              type="text"
              className="form-control"
              placeholder="This an example bio (optional)"
              value={bio}
              onChange={(event) => {
                setBio(event.target.value);
              }}
            ></input>
          </div>
        </div>

        <div>
          {showWarningMessage ? (
            <p className="setup-account-warning-txt">{warningMessage}</p>
          ) : null}
        </div>

        <div className="setup-account-btn-container">
          <button
            onClick={() => {
              TriggerCreateUser(email, password);
            }}
            type="button"
            className="setup-account-btn"
          >
            Enter the Club
          </button>

          <button
            onClick={() => {
              setActiveComponent("createuser");
            }}
            type="button"
            className="go-back-to-create-btn"
          >
            Go back.
          </button>
        </div>
      </div>
    </div>
  );
}
