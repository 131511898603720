// Settings Context
import { useSettings } from "../../../settingsContext";

// CSS
import "./edit-round.css";

interface EditRoundProps {
  selectedMoves: string[];
  setCurrentRound: any;
  round: number;
}

export default function EditRound({
  selectedMoves,
  setCurrentRound,
  round,
}: EditRoundProps) {
  // From settings context
  const { userDocRef } = useSettings();

  return (
    <div className="edit-round-div">
      <h5 style={{ fontFamily: "Staatliches", margin: 0 }}>Round {round}: </h5>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h5 style={{ fontFamily: "Staatliches", margin: "0 1rem 0 0" }}>
          {selectedMoves[round - 1] === "Special"
            ? userDocRef.specialMove
            : selectedMoves[round - 1]}
        </h5>
        <button
          className="edit-round-btn"
          onClick={() => {
            setCurrentRound(round);
          }}
        >
          Edit
        </button>
      </div>
    </div>
  );
}
