// React
import { useNavigate } from "react-router-dom";

// Firebase
import { UpdateDocumentOnFirestore } from "../../../firebaseconfig";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Constants
import { userTableName } from "../../../Constants";

// CSS
import "./brawl-history-item.css";

interface BrawlHistoryItemProps {
  brawlDetails: any;
  index: number;
}

export default function BrawlHistoryItem({
  brawlDetails,
  index,
}: BrawlHistoryItemProps) {
  const { userDocRef, setCurrentBrawlRef, setTransitionActive } = useSettings();

  const navigate = useNavigate();

  const DeleteHistoryItem = async () => {
    userDocRef.brawlHistory = userDocRef.brawlHistory.filter(
      (_: any, i: number) => i !== index
    );

    const userDataUpdate = { brawlHistory: userDocRef.brawlHistory };

    await UpdateDocumentOnFirestore(
      userTableName,
      userDocRef.userID,
      userDataUpdate
    );
  };

  const ViewBrawl = async () => {
    setCurrentBrawlRef(brawlDetails);
    setTransitionActive(true);
    setTimeout(() => {
      setTransitionActive(false);
      navigate("/brawl");
    }, 1000);

    //await DeleteHistoryItem();
  };

  return (
    <div className="brawl-history-item-div">
      <div className="brawl-history-item-profile-container">
        <img
          src={brawlDetails.player2ProfilePic}
          className="brawl-history-item-profile-img"
        ></img>
        <div className="brawl-history-details-txt-container">
          <h4 className="brawl-history-wager-txt">
            {brawlDetails.wagerAmount} $BRAWL
          </h4>
          <h6 className="brawl-history-vs-txt">
            You Vs. {brawlDetails.player2Username}
          </h6>
        </div>
      </div>
      <button
        onClick={async () => {
          await ViewBrawl();
        }}
        className="brawl-history-item-view-btn"
      >
        Replay
      </button>
    </div>
  );
}
