// React
import { useEffect, useState } from "react";

// React Components
import LeaderboardItem from "../Items/LeaderboardItem";

// Settings Context
import { useSettings } from "../../../settingsContext";

// Firebase
import { GetDataForLeaderboard } from "../../../firebaseconfig";

// CSS
import "./ranking-list.css";

interface LeaderboardProps {
  type: string;
}

export default function QuestsList({ type }: LeaderboardProps) {
  const { userDocRef } = useSettings();

  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const usersData = await GetDataForLeaderboard(type);
      setUsers(usersData);
      setLoading(false);
    };

    fetchUsers();
  }, [type]);

  const RenderLeaderboard = () => {
    return users.flatMap((userData: any) => (
      <LeaderboardItem
        key={userData.username}
        type={type}
        userData={userData}
      />
    ));
  };

  const FindUserProfile = () => {
    const user = users.find(
      (userData: any) => userData.username === userDocRef.username
    );

    if (user) {
      return (
        <LeaderboardItem key={user.username} type={type} userData={user} />
      );
    } else {
    }
  };

  return (
    <div>
      <div className="leaderboard-ranking-div">
        {loading ? <p>Loading leaderboard...</p> : RenderLeaderboard()}
      </div>
      <div className="leaderboard-ranking-profile">{FindUserProfile()}</div>
    </div>
  );
}
